import { Select, Card, Checkbox, Button, Modal, message, Form, Input, Space, InputNumber, Divider } from "antd";
import React from "react";
import dayjs from "dayjs";
import api from "api";

const { confirm } = Modal;
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const { TextArea } = Input;
const DiscountPopup = React.forwardRef((props, ref) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [form] = Form.useForm();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setIsModalOpen(true);
      if (id) {
        api.get("Discount/GetByID?id=" + id).then((res) => {
          form.setFieldsValue(res.data);
          setData(data);
        });
      } else {
        form.setFieldsValue({ session_id: props.sessionID, is_freeship: false });
      }
    },
  }));

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const saveDiscount = (values) => {
    values.customer_id = props.customer?.id;
    api
      .post("Discount/Save", values)
      .then((res) => {
        message.success("Lưu thành công");
        handleCancel();
        setTimeout(() => {
          props.reload(props.customer?.id);
        }, 500);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  return (
    <Modal
      title="Cập nhật khuyến mãi"
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Hủy
        </Button>,
        <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={handleOk}>
          Lưu
        </Button>,
      ]}
      maskClosable={false}
      closeIcon={false}
    >
      <Form
        layout={{
          labelCol: {
            span: 8,
          },
          wrapperCol: {
            span: 16,
          },
        }}
        form={form}
        name="control-hooks"
        onFinish={saveDiscount}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item hidden={true} name={"id"} />
        <Form.Item label="Tên" name={"name"}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Giá trị" name="value">
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            size="large"
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Form>
    </Modal>
  );
});
export default DiscountPopup;
