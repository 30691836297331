import React from "react";
import { Layout } from "antd";

import Chat from "./chat";
import CustomRightClick from "./custom_right_click";
import RightChat from "./RightChat";

const { Sider, Content } = Layout;
export const ChatData = React.createContext();
const FbChat = () => {
  const [selectedComment, setSelectedComment] = React.useState();
  const [lstDetailComment, setLstDetailComment] = React.useState([]);
  const [funtionUpdatePhoneRightChat, setFuntionUpdatePhoneRightChat] = React.useState();
  const [funtionAddNotesRightChat, setFuntionAddNotesRightChat] = React.useState();
  const [funtionUpdateAddressRightChat, setFuntionUpdateAddressRightChat] = React.useState();
  const [funtionGetCustomerRightChat, setFuntionGetCustomerRightChat] = React.useState();
  const [customer, setCustomer] = React.useState();
  const [handleContextMenu, setHandleContextMenu] = React.useState();
  const [moveToComment, setMoveToComment] = React.useState();
  React.useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <ChatData.Provider
      value={{
        selectedComment,
        setSelectedComment,
        lstDetailComment,
        setLstDetailComment,
        funtionUpdatePhoneRightChat,
        setFuntionUpdatePhoneRightChat,
        funtionAddNotesRightChat,
        setFuntionAddNotesRightChat,
        funtionUpdateAddressRightChat,
        setFuntionUpdateAddressRightChat,
        funtionGetCustomerRightChat,
        setFuntionGetCustomerRightChat,
        customer,
        setCustomer,
        handleContextMenu,
        setHandleContextMenu,
        moveToComment,
        setMoveToComment,
      }}
    >
      <Layout style={{ height: "100vh" }}>
        <Chat />
        <Sider width={540} style={{ background: "#fff", overflowY: "auto" }}>
          <RightChat />
        </Sider>
      </Layout>
      <CustomRightClick />
    </ChatData.Provider>
  );
};

export default FbChat;
