import React from "react";
import { Button, Tag } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import { useParams, useNavigate } from "react-router-dom";
import AntTable from "component/common/AntTable";
import realtime from "common/Realtime";

const Debt = () => {
  const [totalDebt, setTotalDebt] = React.useState(0);
  const tbDebt = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (tbDebt.current.reload) tbDebt.current.reload();
    realtime.subscribe("customer_transaction", (noti) => {
      tbDebt.current.reload();
    });
  }, []);

  const colDebt = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "customer_name",
    },
    {
      title: "Loại",
      dataIndex: "is_wholesale",
      render: (text, record, index) => {
        return record.is_wholesale ? <Tag color="green">Khách sỉ</Tag> : <Tag color="blue">Khách lẻ</Tag>;
      },
    },
    {
      title: "Số tiền",
      dataIndex: "profix",
      render: (text, record, index) => {
        return formatCurrency(record.profit);
      },
    },
  ];

  return (
    <div>
      <AntTable
        columns={colDebt}
        ajax="Report/ReportCustomerDebt"
        ref={tbDebt}
        title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalDebt)}`} </div>}
        onGetDataFinish={(res) => {
          setTotalDebt(res.sum ?? 0);
        }}
      ></AntTable>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
    </div>
  );
};

export default Debt;
