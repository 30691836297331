import React from "react";
import { Space, Popconfirm, Modal, Form, Input, message, Button, Checkbox, Table, Radio, Tabs, Tag } from "antd";
import api from "api";
import AddressPicker from "./address_picker";
import { formatLongDate, formatCurrency } from "common/Format2";
import {
  EditOutlined,
  ArrowLeftOutlined,
  PhoneFilled,
  EnvironmentFilled,
  DownOutlined,
  DeleteOutlined,
  TruckOutlined,
  PrinterOutlined,
  ApiOutlined,
  FileProtectOutlined,
  SearchOutlined,
  SwapOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import JATCancelOrderPopup from "component/live/cancel_order_jat_popup";
import JATCreateOrderPopup from "component/live/jat_create_order_popup";
import ChangeSession from "component/live/popup_change_live_session";
import TrackingPopup from "component/Fbchat/RightChat/tracking_popup";
import OrderDetail from "./order_detail";
import { Print } from "component/print";

const { confirm } = Modal;
const { TextArea } = Input;
const CustomerLivePopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [recordPrint, setRecordPrint] = React.useState({});
  const [orderID, setOrderID] = React.useState();
  const [tabKey, setTabKey] = React.useState("info");
  const [lstOrder, setListOrder] = React.useState([]);
  const [typePrint, setTypePrint] = React.useState();
  const [cusPrint, setCusPrint] = React.useState({});
  const [addresCus, setAddressCus] = React.useState({});
  const [data, setData] = React.useState({});
  const [lstDetail, setLstDetail] = React.useState([]);
  const [shopConfig, setShopConfig] = React.useState({});
  const [addressShop, setAddressShop] = React.useState({});
  const [id, setId] = React.useState();
  const form = React.useRef();
  const jatCreateOrderPopup = React.useRef();
  const jatCancelOrderPopup = React.useRef();
  const changeSession = React.useRef();
  const trackingRef = React.useRef();
  const printRef = React.useRef();
  const detailRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    open(id, orderID) {
      setOrderID(orderID);
      setVisible(true);
      setId();
      if (id) {
        setId(id);
        setTimeout(() => {
          api.get("CustomerLive/GetByID?id=" + id).then((res) => {
            setData(res.data);
            if (form.current) {
              form.current.setFieldsValue({
                is_wholesale: false,
              });
              form.current.setFieldsValue(res.data);
            }
          });
          getListOrder(id);
        }, 500);
      } else {
        setListOrder([]);
        setData({});
      }
    },
  }));

  const getListOrder = (id) => {
    api.get("LiveOrder/GetOrdersFromCustomerID?id=" + id).then((res) => {
      setListOrder(res.data);
    });
  };

  const tabChange = (key) => {
    setTabKey(key);
  };

  const items = [
    {
      key: "info",
      label: "Thông tin",
    },
    {
      key: "order",
      label: "Đơn hàng",
    },
  ];
  const onFinish = (values) => {
    setLoading(true);
    if (values.phone && values.phone != "") {
      api
        .get(`CustomerLive/CheckPhoneOrder?id=${values.id ?? 0}&phone=${values.phone}`)
        .then((res) => {
          if (res.cus) {
            saveOrderConfirm(res.cus, values);
          } else {
            saveOrder(values);
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      saveOrder(values);
    }
  };

  const saveOrderConfirm = (cus, values) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: values.id
        ? `Số điện thoại ${values.phone} là của khách hàng ${cus.name}. Bạn có muốn thay đổi SDT qua khách này không?`
        : `Số điện thoại ${values.phone} trùng với khách hàng ${cus.name}. Bạn có muốn thay đổi thông tin khách ${cus.name} không?`,
      onOk() {
        saveOrder(values);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const saveOrder = (values) => {
    api
      .post("CustomerLive/Save", values)
      .then((res) => {
        message.success("Lưu thành công");
        setVisible(false);
        setLoading(false);
        if (orderID) api.get(`LiveOrder/ChangeCustomer?id=${orderID}&customerID=${data.id}`);
        close();
      })
      .catch((err) => {
        message.error("Lưu thất bại");
        setLoading(false);
      });
  };

  const close = () => {
    if (props.reload) props.reload();
    form.current.resetFields();
    setVisible(false);
  };

  const columns = [
    {
      title: "Sản phẩm",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        return (
          <div>
            {text.split(",").map((item) => (
              <p>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "SL",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        var products = record.products.split(", ");
        if (products.length == 1) products = record.products.split(",");
        var count = 0;
        products.forEach((x) => {
          var lstSplit = x.split(" ");
          count += lstSplit[0] * 1;
        });
        return <div>{count}</div>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return formatCurrency(text);
      },
    },

    {
      title: "Freeship",
      dataIndex: "is_freeship",
      key: "is_freeship",
      render: function (text, record, index) {
        return <div>{record.is_freeship ? "Miễn phí ship" : "Tính phí ship"}</div>;
      },
    },
    {
      title: "Mã vận đơn",
      dataIndex: "transpost_id",
      key: "transpost_id",
      render: (text, record, index) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              trackingRef.current.open(record.id);
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Trạng thái vận chuyển",
      dataIndex: "transpost_status",
      key: "transpost_status",
      render: function (text, record, index) {
        if (record.transpost_status == "created") {
          return <Tag color="green">Đã gửi đơn</Tag>;
        }
        if (record.transpost_status == "cancelled") {
          return <Tag color="orange">Đã hủy đơn</Tag>;
        }
      },
    },
    {
      title: "Phiên live",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>{`Phiên live ${formatLongDate(record.live_start_time)} - ${record.live_end_time ? formatLongDate(record.live_end_time) : "Chưa kết thúc"}`}</div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => {
                    detailRef.current.open(record.id);
                  }}
                  icon={<EditOutlined />}
                />
                <Popconfirm
                  title="Xóa đơn hàng"
                  description="Bạn có muốn xóa đơn hàng này?"
                  onConfirm={() => deleteOrder(record.id)}
                  okText="Đồng ý"
                  cancelText="Không"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
                <Button
                  type={"primary"}
                  onClick={async () => {
                    var data = await api.get("LiveOrder/GetListOrderDetail?id=" + record.id);
                    if (data.code == 1) setLstDetail(data.data);
                    setRecordPrint(record);
                    setTypePrint("bill_live_order");
                    setTimeout(() => {
                      printRef.current.print();
                    }, 500);
                  }}
                  icon={<PrinterOutlined />}
                ></Button>
              </Space>
            </div>
            <div>
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => {
                    changeSession.current.open(record.id);
                  }}
                  icon={<SwapOutlined />}
                ></Button>
                {record.customer_phone && record.customer_address && record.customer_address_code && record.transpost_status != "created" ? (
                  <Button
                    type={"primary"}
                    onClick={() => {
                      jatCreateOrderPopup.current.open(record.id);
                    }}
                    icon={<TruckOutlined />}
                  />
                ) : (
                  <></>
                )}
                {record.transpost_status == "created" ? (
                  <>
                    <Button
                      icon={<ApiOutlined />}
                      type={"primary"}
                      onClick={() => {
                        jatCancelOrderPopup.current.open(record.id);
                      }}
                    ></Button>
                    <Button
                      icon={<FileProtectOutlined />}
                      type={"primary"}
                      onClick={() => {
                        if (!record.customer_id) {
                          message.error("Dữ liệu đã cũ.");
                          return;
                        }
                        printBillJAT(record);
                      }}
                    ></Button>
                  </>
                ) : (
                  <></>
                )}
              </Space>
            </div>
          </div>
        );
      },
    },
  ];

  const deleteOrder = (id) => {
    api.get("LiveOrder/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công!");
      props.getLstOrder();
    });
  };

  const printBillJAT = (record) => {
    api.get("CustomerLive/GetByID?id=" + record.customer_id).then((res) => {
      record.jat_respone = JSON.parse(record.jat_create_order_respone);

      api.get("Address/GetAddressByCode?code=" + res.data.address_code).then((res2) => {
        setAddressCus(res2.data);
        setCusPrint(res.data);
        setRecordPrint(record);
        setTypePrint("bill_jat_order");
        setTimeout(() => {
          printRef.current.print();
        }, 1000);
      });
    });
  };

  return (
    <Modal
      maskClosable={false}
      width={1000}
      open={visible}
      title={"Thông tin khách hàng"}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form.current.submit();
      }}
      okText="Sửa"
      cancelText="Thoát"
      footer={[
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.current.submit();
          }}
          disabled={tabKey == "order"}
        >
          Lưu
        </Button>,
      ]}
    >
      <Tabs activeKey={tabKey} items={items} onChange={tabChange} />

      <Form layout="vertical" ref={form} onFinish={onFinish} hidden={tabKey == "info" ? false : true}>
        <Form.Item hidden={true} name={"id"} />
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            style={{ flex: 1 }}
            name="name"
            label="Tên khách"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Số điện thoại" style={{ flex: 1 }}>
            <Input disabled={data?.phone != null && data?.phone != ""} />
          </Form.Item>
        </div>

        <Form.Item name="note" label="Ghi chú">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name={"address_code"} label={"Địa chỉ"}>
          <AddressPicker />
        </Form.Item>
        <Form.Item name="address">
          <Input placeholder="Địa chỉ" />
        </Form.Item>
        <div style={{ display: "flex", gap: 50 }}>
          <Form.Item name={"is_wholesale"}>
            <Radio.Group>
              <Radio value={true}> Khách sỉ </Radio>
              <Radio value={false}> Khách lẻ </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item valuePropName="checked" name={"is_warning"}>
            <Checkbox>Cảnh báo</Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name={"is_goods_bomb"}>
            <Checkbox>Bom hàng</Checkbox>
          </Form.Item>
        </div>
      </Form>
      <div hidden={tabKey == "order" ? false : true}>
        <Table dataSource={lstOrder} columns={columns} />
      </div>
      <JATCreateOrderPopup ref={jatCreateOrderPopup} reload={() => getListOrder(id)} printBillJAT={printBillJAT}></JATCreateOrderPopup>
      <JATCancelOrderPopup ref={jatCancelOrderPopup} reload={() => getListOrder(id)}></JATCancelOrderPopup>
      <ChangeSession ref={changeSession} reload={() => getListOrder(id)} />
      <TrackingPopup ref={trackingRef} />
      <OrderDetail ref={detailRef} getData={() => getListOrder(id)} />
      <div style={{ display: "none" }}>
        <Print
          type={typePrint}
          data={recordPrint}
          cus={cusPrint}
          config={shopConfig}
          shopAddres={addressShop}
          customerAddres={addresCus}
          ref={printRef}
          details={lstDetail}
        />
      </div>
    </Modal>
  );
});
export default CustomerLivePopup;
