import React, { useState, useEffect, useRef } from "react";
import { ChatData } from "./index";
import { Input, Avatar, Button, List, Tooltip, Modal, message, Upload, Tag, Popover, Row, Col, Popconfirm, Spin, Card } from "antd";
import api from "api";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import FbImageLibrary from "react-fb-image-grid";
import {
  MessageOutlined,
  PhoneOutlined,
  SendOutlined,
  SmileOutlined,
  FileOutlined,
  PictureOutlined,
  EllipsisOutlined,
  TagOutlined,
  EnvironmentFilled,
  PhoneFilled,
  LikeOutlined,
  PaperClipOutlined,
  SisternodeOutlined,
  ProductOutlined,
  EyeInvisibleOutlined,
  SearchOutlined,
  ReloadOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  DeleteOutlined,
  FacebookOutlined,
  MailOutlined,
  NodeExpandOutlined,
  SyncOutlined,
  InteractionTwoTone,
  CarryOutOutlined,
} from "@ant-design/icons";
import "./style.css";
import * as FacebookAPI from "./FacebookAPI";
import moment from "moment";
import { imageURL } from "config/index";
import { uploadImageURL } from "config/index";
import axios from "axios";
import AddTagModal from "./tag";
import ModalMessage from "./inbox_from_comment";
import Template from "./template_message";
import PostInfo from "./post_info";
import realtime from "common/Realtime";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import { formatShortDate } from "common/Format2";
import { zaloUrl } from "config";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const DetailChat = (props) => {
  const [lstDetailCommentSearch, setLstDetailCommentSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState(props.selectedComment);
  // const [text, setText] = useState("");
  const text = useRef("");
  const textareaRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const { funtionUpdatePhoneRightChat, funtionAddNotesRightChat, funtionUpdateAddressRightChat, handleContextMenu, customer, setMoveToComment } =
    React.useContext(ChatData);
  const chatContentRef = useRef(null);
  const [isEmojiModalVisible, setIsEmojiModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isModalTagVisible, setIsModalTagVisible] = useState(false);
  const [searchTextTag, setSearchTextTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [availableTag, setAvailableTag] = useState([]);
  const [isModalMessage, setIsModalMessage] = useState(false);
  const [isModalTemplateVisible, setIsModalTemplateVisible] = useState(false);
  const { lstDetailComment, setLstDetailComment } = React.useContext(ChatData);
  const [loadingchat, setLoadingChat] = useState(false);
  const [cmtsendMessage, setCmtsendMessage] = useState({});
  const [isreLoading, setIsreLoading] = useState(false);
  const [replyingTo, setReplyingTo] = useState({});
  const [isShowPostInfo, setIsShowPostInfo] = useState(false);
  const [selectedCmtPost, setSelectedCmtPost] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const pagging = useRef(1);

  const loadMoreComments = async () => {
    pagging.current += 1;
    setIsLoading(true);
    var data = await api.get("Facebook/LoadDetailChat?id=" + selectedComment.id + "&pagging=" + pagging.current);
    data = data.data;
    setIsLoading(false);
    if (data.length > 0) {
      setHasMore(data.length == pagging.current * 20 ? true : false);
      setLstDetailComment((prevData) => {
        return [...data, ...prevData];
      });
      setLstDetailCommentSearch(lstDetailComment);
    } else {
      setHasMore(false);
    }
    return data;
  };

  realtime.subscribe("commentchild_changed", (data) => {
    try {
      if (data && data.childof == selectedComment.id) {
        const index = lstDetailComment.findIndex((comment) => comment.id === data.id);
        if (index == -1) {
          setLstDetailComment((prevComments) => [...prevComments, data]);
        }
        setLoadingChat(false);
      }
    } catch (error) {}
  });

  useEffect(() => {
    setHasMore(true);
    pagging.current = 1;
    getCurrentTag();
    loadData();
    setSelectedTags(selectedComment ? (selectedComment.tag ? selectedComment.tag.split(",") : []) : []);
  }, []);

  useEffect(() => {
    setMoveToComment(() => moveToDetailComment);
  }, [lstDetailComment]);

  useEffect(() => {
    setTimeout(() => {
      text.current = "";
      setFileList([]);
    }, 0);
  }, [props.closeMultipleChat]);
  useEffect(() => {
    if (selectedComment && props.selectedComment.id !== selectedComment.id) {
      setSelectedComment(props.selectedComment);
      loadData();
      setSelectedTags(props.selectedComment.tag ? props.selectedComment.tag.split(",") : []);
    }
  }, [props.selectedComment]);
  useEffect(() => {
    loadData();
  }, [props.curentComment]);
  const getCurrentTag = () => {
    api.get("Facebook/GetCurrentTag").then((res) => {
      setAvailableTag(res.data);
    });
  };
  const loadData = () => {
    const item = props.selectedComment;
    if (item) {
      pagging.current = 1;
      setIsLoading(true);
      setLstDetailComment([]);
      setReplyingTo({});
      api.get("Facebook/LoadDetailChat?id=" + item.id + "&pagging=1").then((res) => {
        setIsLoading(false);
        var data = res.data;
        if (data.length > 0) {
          setHasMore(data.length == 20 ? true : false);
          setLstDetailComment(data);
          setLstDetailCommentSearch(data);
          setReadedComment();
        } else setHasMore(false);
      });
    }
  };
  const setReadedComment = () => {
    const item = props.selectedComment;
    var dataToPost = { ID: item.id, Type: item.typeof };
    api.post("Facebook/UpdateRead", dataToPost).then((data) => {});
  };
  const setUnReadedComment = () => {
    const item = props.selectedComment;
    var dataToPost = { ID: item.id, Type: item.typeof };
    api.post("Facebook/UpdateUnread", dataToPost).then((data) => {});
  };

  const sendReplyComment = async () => {
    var lastcmt = getLastDetailComment(lstDetailComment);
    var selectcmt = selectedComment;
    if (props.typesearch == "comment" && !replyingTo.id) {
      lastcmt = getLastDetailComment2(lstDetailComment);
      setReplyingTo(lastcmt);
      selectcmt = lastcmt;
    } else if (replyingTo.id) {
      selectcmt = replyingTo;
    }
    setLoadingChat(true);
    if (text.current === "" && fileList.length === 0) {
      Modal.error({
        title: "Lỗi API",
        content: "Bạn phải nhập nội dung hoặc chọn hình ảnh",
      });
      setLoadingChat(false);
      return;
    }

    if (selectedComment && !selectedComment.page_token && !selectedComment.is_zalo) {
      Modal.error({
        title: "Lỗi Token",
        content: "Token không hợp lệ. Vui lòng kiểm tra lại token của bạn.",
      });
      setLoadingChat(false);
      return;
    }

    if (fileList.length !== 0 && !selectedComment.is_zalo) {
      if (props.from == "comment" || (lastcmt.typeof == "comment" && lastcmt.userid != lastcmt.pageid)) {
        message.error("Gửi tin nhắn từ comment (riêng tư) không thể gửi kèm hình ảnh");
        setLoadingChat(false);
        return;
      }
      if (props.lstSelectChat && props.lstSelectChat.length > 0) {
        for (const e of props.lstSelectChat) {
          if (e.is_zalo) {
            await delay(1000);
            sendZaloMessage(e);
          } else replyWithImage(text.current, fileList, e);
        }
        message.success("Gửi tin nhắn thành công");

        props.closeMultipleChat();
      } else replyWithImage(text.current, fileList, selectcmt);
    } else {
      if (!replyingTo.id && (props.from == "comment" || (props.typesearch != "comment" && lastcmt.typeof == "comment" && lastcmt.userid != lastcmt.pageid))) {
        if (props.from == "comment") replyprivateText(text.current);
        else replyprivateText(text.current, lastcmt);
      } else {
        if (props.lstSelectChat && props.lstSelectChat.length > 0) {
          for (const e of props.lstSelectChat) {
            if (e.is_zalo) {
              await delay(1000);
              sendZaloMessage(e);
            } else replyText(text.current, e);
          }
          message.success("Gửi tin nhắn thành công");
          setLoadingChat(false);
          props.closeMultipleChat();
        } else {
          if (selectcmt.is_zalo) {
            sendZaloMessage(selectcmt);
          } else replyText(text.current, selectcmt);
        }
      }
    }
  };

  const sendZaloMessage = (e) => {
    var dataPost = {
      threadId: e.userid,
      msg: text.current,
      is_group: e.is_group,
    };
    if (fileList.length > 0) {
      dataPost.fileList = fileList.map((x) => x.url);
    }
    axios
      .request({
        method: "post",
        url: zaloUrl + "send-message",
        headers: {
          "Content-Type": "application/json",
        },
        data: dataPost,
      })
      .then((res) => {
        resetText();
        setFileList([]);
      })
      .catch();
  };

  const replyText = (message, selectedCmt = null) => {
    var curentComment = selectedComment;
    if (selectedCmt) {
      curentComment = selectedCmt;
    }
    message = message.replace("{namefb}", curentComment.username);
    var Token = curentComment.page_token ?? selectedComment.page_token;
    var cmID = curentComment.id;
    FacebookAPI.replyFbAPI(cmID, curentComment.pageid, Token, message, curentComment.typeof, null, curentComment.userid).then(
      (res) => {
        var form = {
          PageID: curentComment.pageid,
          CommentID: curentComment.id,
          Message: message,
          Type: curentComment.typeof,
          ReturnID: res.message_id ? res.message_id : res.id,
          WithPhoto: false,
        };
        saveMessageToServer(form);
        if (props.type !== "multiple") {
          resetText();
          setFileList([]);
        }
      },
      (error) => {
        error = JSON.parse(error).error;
        if (props.type !== "multiple") {
          if (props.type !== "multiple") {
            resetText();
            setFileList([]);
            Modal.error({
              title: "Error",
              content: error.message,
            });
            setLoadingChat(false);
          }
        }
      }
    );
  };

  const saveMessageToServer = (form) => {
    api.post("Facebook/SaveReplyComment", form).then((res) => {
      // setLstDetailComment((prevComments) => [...prevComments, res.comment]);
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendReplyComment();
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file) {
          if (file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");
          uploadImage(file);
        }
      }
    }
  };
  const uploadImage = (file) => {
    console.log("Starting upload for:", file);

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true,
    };
    fmData.append("file", file);
    fmData.append("type", "image");

    axios
      .post(uploadImageURL, fmData, config)
      .then((res) => {
        console.log("Upload successful:", res.data);
        const newFile = {
          uid: fileList.length.toString(),
          name: res.data,
          url: `${imageURL}${res.data}`,
        };
        const fileExists = fileList.some((item) => item.uid === newFile.uid);
        if (!fileExists) {
          setFileList((prevList) => [newFile, ...prevList]);
        }
      })
      .catch((err) => {
        console.error("Upload failed:", err);
      });
  };
  const deleteImage = (index) => {
    const newFileList = fileList.filter((_, i) => i !== index);
    setFileList(newFileList);
  };
  const renderPreviews = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: 8,
        }}
      >
        {fileList.map((file, index) => {
          const url = file.url;
          return (
            <div key={index} style={{ width: "100px", height: "100px" }}>
              <button
                onClick={() => deleteImage(index)}
                style={{
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                  fontSize: "14px",
                  zIndex: 1,
                  float: "right",
                  top: 0,
                }}
              >
                X
              </button>
              <img src={url} onClick={deleteImage} alt="preview" style={{ width: "80%", height: "80%", objectFit: "cover" }} />
            </div>
          );
        })}
      </div>
    );
  };

  const replyWithImage = (message, lstImage, selectedCmt = null) => {
    var curentComment = selectedComment;
    if (selectedCmt) {
      curentComment = selectedCmt;
    }
    message = message.replace("{namefb}", curentComment.username);
    var Token = curentComment.page_token ?? selectedComment.page_token;
    if (curentComment.typeof == "inbox") {
      replyInboxWithImage(message, lstImage, Token, curentComment);
    } else {
      fileList.forEach((element) => {
        setTimeout(() => {
          reply1Image(message, element, Token, curentComment);
        }, 500);
      });
    }
  };
  const replyInboxWithImage = (message, images, token, selectedCmt = null) => {
    var curentComment = selectedComment;
    if (selectedCmt) {
      curentComment = selectedCmt;
    }
    var cmID = curentComment.id;
    var tagChat = null;
    if (message != "") {
      FacebookAPI.replyFbAPI(cmID, curentComment.pageid, token, message, curentComment.typeof, tagChat, curentComment.userid).then(
        (res) => {
          var form = {
            PageID: curentComment.pageid,
            CommentID: curentComment.id,
            Message: message,
            Type: curentComment.typeof,
            ReturnID: res.message_id,
            WithPhoto: false,
          };
          saveMessageToServer(form);
          if (props.type !== "multiple") {
            resetText();
            setFileList([]);
          }
        },
        (error) => {
          error = JSON.parse(error).error;
          if (props.type !== "multiple") {
            Modal.error({
              title: "Error",
              content: error.message,
            });
            setLoadingChat(false);
          }
        }
      );
    }
    images.forEach((item, index) => {
      setTimeout(() => {
        FacebookAPI.replyInboxWithPhoto(cmID, curentComment.pageid, token, "", item, tagChat, curentComment.userid).then(
          (response) => {
            var form = {
              PageID: curentComment.pageid,
              CommentID: curentComment.id,
              Message: "",
              Type: curentComment.typeof,
              ReturnID: response.message_id,
              WithPhoto: true,
            };
            saveMessageToServer(form);

            if (props.type !== "multiple") {
              resetText();
              setFileList([]);
            }
          },

          (error) => {
            error = JSON.parse(error).error;
            if (props.type !== "multiple") {
              Modal.error({
                title: "Error",
                content: "Error sending message: " + error.message,
              });
              setLoadingChat(false);
            }
          }
        );
      }, 200 * index);
    });
  };
  const reply1Image = (message, image, token, selectedCmt = null) => {
    var curentComment = selectedComment;
    if (selectedCmt) {
      curentComment = selectedCmt;
    }
    var item = image;
    var cmID = curentComment.id;
    FacebookAPI.replyWithPhotoData(cmID, curentComment.pageid, token, message, item, curentComment.typeof).then(
      (response) => {
        var form = {
          PageID: curentComment.pageid,
          CommentID: curentComment.id,
          Message: message,
          Type: curentComment.typeof,
          ReturnID: response.id,
          WithPhoto: true,
        };
        saveMessageToServer(form);
        resetText();
        setFileList([]);
      },

      (error) => {
        error = JSON.parse(error).error;
        Modal.error({
          title: "Error",
          content: error.message,
        });
        setLoadingChat(false);
      }
    );
  };
  const sendLikeIcon = () => {
    const likeIcon = "👍";
    replyText(likeIcon);
  };
  const openEmojiModal = () => {
    setIsEmojiModalVisible(true);
  };
  const openMessageModal = (cmt) => {
    setIsModalMessage(true);
    setCmtsendMessage(cmt);
  };

  const closeModalMessage = () => {
    setIsModalMessage(false);
    loadData();
  };

  const closeEmojiModal = () => {
    setIsEmojiModalVisible(false);
  };

  const addEmojiToText = (emoji) => {
    text.current = text.current + emoji.native;
    textareaRef.current.value = text.current;
    closeEmojiModal();
  };

  const handleSearchChange = (e) => {
    setHasMore(false);
    const searchValue = e.target.value.toLowerCase();
    setSearchText(searchValue);

    const filtered = lstDetailCommentSearch.filter((item) => {
      return item.text.toLowerCase().includes(searchValue);
    });
    setLstDetailComment(filtered);
  };

  const refreshComment = () => {
    var comment = selectedComment;

    setIsLoading(true);
    api
      .post("Facebook/RefreshComment", {
        postid: comment.postid,
        pageid: comment.pageid,
        token: comment.page_token,
      })
      .then((data) => {
        setIsLoading(false);
        if (data != null) {
          loadData();
          message.success("Tải lại bài viết thành công");
        }
      });
  };

  const refreshInbox = () => {
    setIsLoading(true);
    var inbox = selectedComment;
    api
      .post("Facebook/RefreshInbox", {
        pageid: inbox.pageid,
        userid: inbox.userid,
      })
      .then((data) => {
        setIsLoading(false);
        if (data != null) {
          loadData();
          message.success("Thành công");
        }
      });
  };
  const inputSearch = () => {
    setIsSearching(!isSearching);
  };
  const clearSearch = () => {
    if (searchText != null && searchText != "") {
      setHasMore(true);
      setSearchText("");
      loadData();
    }
    setIsSearching(!isSearching);
  };
  const showModalTag = () => {
    setIsPopoverVisible(false);
    setIsModalTagVisible(true);
  };

  const handleTagOk = () => {
    setIsModalTagVisible(false);
    getCurrentTag();
  };

  const handleCancelTag = () => {
    setIsModalTagVisible(false);
  };
  const handleSearchTagChange = (e) => {
    setSearchTextTag(e.target.value);
  };
  const handleTagClick = (tag) => {
    setSelectedTags((prevTags) => {
      const newTags = prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag];
      setTimeout(() => {
        const tags = newTags.toString();
        api
          .post("Facebook/SaveCommentTag", {
            commentid: selectedComment.id,
            tags: tags,
          })
          .then((data) => {
            props.onTagChange(tags, selectedComment.id);
          });
      }, 500);

      return newTags;
    });
  };

  const filteredTags = availableTag.filter((tag) => tag.name.toLowerCase().includes(searchTextTag));

  const popoverContent = (
    <div style={{ maxWidth: "400px", minWidth: "400px" }}>
      <Input placeholder="Tìm kiếm nhãn" prefix={<SearchOutlined />} value={searchTextTag} onChange={handleSearchTagChange} />
      <div style={{ marginTop: 16 }}>
        {filteredTags.map((tag, index) => (
          <>
            <Tag key={index} color={"#" + tag.color} onClick={() => handleTagClick(tag.id.toString())} style={{ cursor: "pointer" }}>
              {selectedTags.includes(tag.id.toString()) && <CheckOutlined />} {tag.name}
            </Tag>
            <Button type="link" onClick={() => DeleteTag(tag.id)} style={{ padding: 0, marginRight: "10px", marginLeft: "-5px" }}>
              <DeleteOutlined style={{ color: "#" + tag.color }} />
            </Button>
          </>
        ))}
      </div>
      <Button onClick={showModalTag} type="dashed" icon={<PlusOutlined />} style={{ marginTop: 16, width: "100%" }}>
        Thêm thẻ tag
      </Button>
    </div>
  );

  const DeleteTag = (tagId) => {
    Modal.confirm({
      title: "Xóa thẻ",
      content: "Bạn có chắc chắn muốn xóa thẻ này?",
      okText: "Có",
      cancelText: "Không",
      onOk: () => {
        api.post("Facebook/DeleteTag?id=" + tagId).then((data) => {
          getCurrentTag();
        });
      },
    });
  };

  const updateLike = (item) => {
    var mode = !item.userlike;
    FacebookAPI.likeAction(item.id, mode, selectedComment.page_token).then((res) => {
      item.userlike = mode;
      api.post("Facebook/UpdateLike", { flag: mode, commentid: item.id }).then(() => {});
      setLstDetailComment((prevItems) => prevItems.map((i) => (i.id === item.id ? { ...i, userlike: mode } : i)));
    });
  };
  const updateHide = (item) => {
    var mode = !item.ishidden;
    FacebookAPI.hideAction(item.id, mode, selectedComment.page_token).then((res) => {
      item.ishidden = mode;
      api.post("Facebook/UpdateHide", { flag: mode, commentid: item.id });
      setLstDetailComment((prevItems) => prevItems.map((i) => (i.id === item.id ? { ...i, ishidden: mode } : i)));
    });
  };
  const updateDelete = (item) => {
    FacebookAPI.deleteAction(item.id, selectedComment.page_token).then((res) => {
      item.isdelete = true;
      api.post("Facebook/UpdateDelete?commentid=" + item.id);
      message.success("Xóa bình luận thành công");
    });
  };

  const replyprivateText = (message, curentComment = null) => {
    if (curentComment == null) curentComment = props.curentComment;
    message = message.replace("{namefb}", curentComment.username);
    FacebookAPI.privateMessage(curentComment.id, message, selectedComment.page_token).then(
      (res) => {
        resetText();
        setFileList([]);
        api
          .post("Facebook/UpdateIsSendInbox", {
            id: curentComment.id,
          })
          .then((res) => {
            setLoadingChat(false);
          });
      },
      (error) => {
        error = JSON.parse(error).error;
        console.log(error);
        if (error.code == "10900") {
          api
            .post("Facebook/UpdateIsSendInbox", {
              id: props.curentComment.id,
            })
            .then((res) => {});
          Modal.error({
            title: "Error",
            content: "Comment này đã gửi tin nhắn riêng tư",
          });
        } else if (error.code == 190 && error.error_subcode == 460) {
          Modal.error({
            title: "Error",
            content: "Token đã hết hạn vui lòng đồng bộ lại",
          });
        } else if (error.code == 100 && error.error_subcode == 33) {
          Modal.error({
            title: "Error",
            content: "Cmt của khách này đã bị xóa",
          });
        } else {
          Modal.error({
            title: "Error",
            content: error.message,
          });
        }
        setLoadingChat(false);
      }
    );
  };

  const handleCloseTemplate = () => {
    setIsModalTemplateVisible(false);
  };
  const showModalTemplate = () => {
    setIsModalTemplateVisible(true);
  };
  var height = `calc(100vh  - 0px)`;
  if (props.height) {
    height = props.height;
  }

  const applyTemplate = (message) => {
    setIsModalTemplateVisible(false);
    var array = getLstImageFromText(message);
    if (array.length > 0) {
      message = replaceLstImages(message);
      text.current = message;
      textareaRef.current.value = message;
      const newFileList = [];
      array.forEach((element, i) => {
        const newFile = {
          uid: i.toString(),
          name: i.toString(),
          url: element,
        };
        newFileList.push(newFile);
      });
      setFileList(newFileList);
    } else {
      text.current = message;
      textareaRef.current.value = message;
    }
  };

  const replaceLstImages = (text) => {
    var str = getLstImageStr(text);
    if (str) {
      text = text.replace(str, "");
    }
    return text;
  };

  const getLstImageStr = (text) => {
    var pttr = /{\"images.*}/g;
    if (text) {
      var images = text.match(pttr);
      if (images === null) return null;
      return images[0];
    }
    return null;
  };

  const getLstImageFromText = (text) => {
    if (text) {
      var images = getLstImageStr(text);

      if (images === null) return [];
      try {
        return JSON.parse(images).images;
      } catch {
        return [];
      }
    }
    return [];
  };

  const closePostInfo = () => {
    setIsShowPostInfo(false);
  };
  const showPostInfo = (select) => {
    setIsShowPostInfo(true);
    setSelectedCmtPost(select);
  };
  const ReloadFile = (item) => {
    setIsreLoading(true);
    api
      .post("Facebook/RefreshFile", {
        Typeof: item.typeof,
        cmtid: item.id,
        pageid: item.pageid,
        type: item.previewurl,
      })
      .then((data) => {
        setIsreLoading(false);
        if (data.code == 1) {
          setLstDetailComment((prevListDetail) =>
            prevListDetail.map((detail) => (detail.id === data.data.id ? { ...detail, note: data.data.note, previewurl: data.data.previewurl } : detail))
          );
        }
      });
  };
  const handleReplyClick = (comment) => {
    setReplyingTo(replyingTo.id === comment.id ? {} : comment);
  };

  const getLastDetailComment = (comments) => {
    if (!comments || comments.length === 0) return [];
    var sortlist = [...comments].sort((a, b) => moment(b.time) - moment(a.time));
    return sortlist[0];
  };
  const getLastDetailComment2 = (comments) => {
    if (!comments || comments.length === 0) return [];
    var usercomments = comments.filter((comment) => comment.typeof === "comment" && comment.userid !== comment.pageid);
    var sortlist = usercomments.sort((a, b) => moment(b.time) - moment(a.time));
    return sortlist[0];
  };
  const onTextChange = (e) => {
    text.current = e.target.value;
    textareaRef.current.value = e.target.value;
  };
  const resetText = () => {
    text.current = "";
    textareaRef.current.value = "";
  };
  const checkDate = (inputDate) => {
    const today = dayjs(); // Ngày hôm nay
    const yesterday = dayjs().subtract(1, "day"); // Ngày hôm qua
    const input = dayjs(inputDate); // Ngày đầu vào

    if (input.isSame(today, "day")) {
      return "Hôm nay";
    } else if (input.isSame(yesterday, "day")) {
      return "Hôm qua";
    } else {
      return formatShortDate(inputDate);
    }
  };

  const renderShareFile = (item) => {
    var files = item.note.trim().split("\n");
    return (
      <div>
        {files.map((file) => {
          return (
            <a style={{ color: "#010893" }} href={file}>
              {file}
            </a>
          );
        })}
      </div>
    );
  };

  const renderChatRecommend = (item) => {
    var des = JSON.parse(item.description);
    return (
      <div style={{ display: "flex" }}>
        <div style={{ minWidth: 100, marginRight: 50 }}>
          <img src={item.note} style={{ width: 50, height: 50, borderRadius: "50%" }} />
          <div>{item.text}</div>
          <div>{des?.phone}</div>
        </div>
        <div>
          <div style={{ fontSize: 10, textAlign: "center" }}>Danh thiếp zalo</div>
          <img src={des?.qrCodeUrl} style={{ width: 80, height: 80 }} />
        </div>
      </div>
    );
  };

  const renderPhoto = (item) => {
    const images2 = item.note.trim().split("\n");
    return (
      <div
        style={{
          minWidth: "150px",
          minHeight: "150px",
          maxWidth: "150px",
          overflow: "hidden",
          display: "flex",
          marginLeft: "auto",
        }}
      >
        <FbImageLibrary
          hideOverlay={true}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          images={images2.map((image) => image)}
          width={40}
          countFrom={3}
        />
      </div>
    );
  };
  const moveToDetailComment = (id) => {
    handleMoveToDetailComment(id);
  };

  const handleMoveToDetailComment = async (id) => {
    var lst = [...lstDetailComment];
    var check = lst.find((x) => x.id == id);
    while (!check) {
      var newlst = await loadMoreComments();
      var check = newlst.find((x) => x.id == id);
    }
    setTimeout(() => {
      const element = document.querySelector(`#cmt-${id}`);
      element.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };
  return (
    <div>
      <div className="chat-window-container" style={{ height: height }}>
        {props.from !== "comment" &&
          props.type !== "multiple" &&
          (!isSearching ? (
            <div className="chat-header">
              <div style={{ position: "relative" }}>
                <Avatar
                  src={
                    selectedComment.is_zalo
                      ? selectedComment.avatar
                      : `https://graph.facebook.com/${selectedComment.userid}/picture?height=100&width=100&access_token=${selectedComment.page_token}`
                  }
                  size="large"
                />

                {selectedComment.is_zalo ? (
                  <img style={{ width: 20, height: 20, position: "absolute", bottom: -3, right: -5 }} src="/image/zalo.png" />
                ) : (
                  <img style={{ width: 20, height: 20, position: "absolute", bottom: -3, right: -5 }} src="/image/facebook.png" />
                )}
              </div>

              <div className="chat-user-info">
                <h3>{selectedComment.username}</h3>
              </div>
              <div className="chat-actions">
                <Tooltip title="Đánh dấu tin nhắn chưa đọc">
                  <Button icon={<EyeInvisibleOutlined />} type="text" onClick={() => setUnReadedComment()} />
                </Tooltip>
                <Tooltip title="Tìm kiếm">
                  <Button icon={<SearchOutlined />} type="text" onClick={inputSearch} />
                </Tooltip>
                {selectedComment.typeof === "comment" && (
                  <Tooltip title="Tải lại bình luận">
                    <Button loading={isLoading} type="text" onClick={() => refreshComment()} icon={<ReloadOutlined />}></Button>
                  </Tooltip>
                )}
                {selectedComment.typeof === "inbox" && (
                  <Tooltip title="Tải lại tin nhắn">
                    <Button loading={isLoading} type="text" onClick={() => refreshInbox()} icon={<ReloadOutlined />}></Button>
                  </Tooltip>
                )}
              </div>
            </div>
          ) : (
            <div className="chat-header">
              <Input
                prefix={<SearchOutlined />}
                suffix={<CloseOutlined onClick={clearSearch} />}
                placeholder="Nhập nội dung cần tìm"
                value={searchText}
                onChange={handleSearchChange}
              />
            </div>
          ))}
        {props.type !== "multiple" && (
          <>
            <div
              id="scrollableDiv"
              style={{
                height: height,
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
              className="chat-content"
              ref={chatContentRef}
            >
              <InfiniteScroll
                dataLength={lstDetailComment.length}
                next={loadMoreComments}
                style={{ display: "flex", flexDirection: "column-reverse" }}
                inverse={true}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                initialScrollY={lstDetailComment.length}
              >
                <List
                  dataSource={lstDetailComment}
                  renderItem={(item, index) => {
                    var pre = lstDetailComment[index - 1];
                    var showDate = false;
                    var date = "";
                    if (pre) {
                      if (!dayjs(item.time).isSame(dayjs(pre?.time), "day")) {
                        showDate = true;
                        date = checkDate(item.time);
                      }
                    } else {
                      showDate = true;
                      date = checkDate(item.time);
                    }
                    const parentComment = lstDetailComment.find((parent) => parent.id === item.childof2 && item.id != parent.id);
                    return (
                      <>
                        {showDate ? (
                          <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "0 10px" }}>
                            <div style={{ padding: "1px 10px", backgroundColor: "#dfdfdf", borderRadius: "10px" }}>{date}</div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <List.Item
                          style={{ marginLeft: parentComment ? "20px" : "0px" }}
                          className={item.userid === item.pageid ? "message right" : "message left"}
                          onContextMenu={(e) => handleContextMenu(e, customer, item)}
                          id={"cmt-" + item.id}
                        >
                          {item.userid != item.pageid && (
                            <Avatar
                              src={
                                item.is_zalo
                                  ? item.avatar
                                  : `https://graph.facebook.com/${item.userid}/picture?height=100&width=100&access_token=${selectedComment.page_token}`
                              }
                              size={30}
                              style={{ marginRight: "8px" }}
                            />
                          )}

                          <div className="message-content-wrapper" style={{ padding: "0px" }}>
                            <Tooltip
                              title={
                                moment(item.time).add(7, "hours").isSame(moment(), "day")
                                  ? moment(item.time).add(7, "hours").format("HH:mm")
                                  : moment(item.time).add(7, "hours").format("DD-MM HH:mm")
                              }
                              placement="top"
                            >
                              {item.is_group && item.userid != item.pageid ? <div style={{ fontSize: 10, fontWeight: "bold" }}>{item.username}</div> : <></>}
                              <span className="message-content">
                                {item.previewurl == "video/mp4" || item.previewurl == "chat.video.msg" ? (
                                  <video controls width="200">
                                    <source src={item.note} type="video/mp4" />
                                  </video>
                                ) : item.previewurl == "share.file" ? (
                                  renderShareFile(item)
                                ) : item.previewurl == "chat.recommended" ? (
                                  renderChatRecommend(item)
                                ) : item.previewurl == "audio/mpeg" || item.previewurl == "chat.voice" ? (
                                  <audio controls>
                                    <source src={item.note} type="audio/mpeg" />
                                  </audio>
                                ) : item.previewurl == "photo" || item.previewurl == "image/jpeg" || item.previewurl == "chat.photo" ? (
                                  renderPhoto(item)
                                ) : (
                                  <p>{item.text}</p>
                                )}
                                {item.userid !== item.pageid && item.typeof === "comment" && props.from != "comment" && !item.isdelete && (
                                  <div style={{ display: "inline-flex", gap: "15px", alignItems: "center" }}>
                                    <Tooltip placement="bottom" title={item.userlike ? "Dislike" : "Like"}>
                                      <span style={{ color: item.userlike ? "#1677ff" : "inherit" }} onClick={() => updateLike(item)}>
                                        <LikeOutlined />
                                      </span>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={"Phản hồi"}>
                                      <span
                                        style={{ color: replyingTo ? (replyingTo.id === item.id ? "#1677ff" : "inherit") : "" }}
                                        onClick={() => handleReplyClick(item)}
                                      >
                                        <SendOutlined />
                                      </span>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={"Thông tin bài viết"}>
                                      <span onClick={() => showPostInfo(item)}>
                                        <CarryOutOutlined />
                                      </span>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={item.ishidden ? "Hiện" : "Ẩn"}>
                                      <span style={{ color: item.ishidden ? "#1677ff" : "inherit" }} onClick={() => updateHide(item)}>
                                        <EyeInvisibleOutlined />
                                      </span>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={"Gửi tin nhắn"}>
                                      <span style={{ color: item.issendinbox ?? false ? "#1677ff" : "inherit" }} onClick={() => openMessageModal(item)}>
                                        <MailOutlined />
                                      </span>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={item.isdelete ? "Đã xóa" : "Xóa bình luận"}>
                                      <Popconfirm
                                        placement="top"
                                        title={"Xóa bình luận"}
                                        onConfirm={() => {
                                          updateDelete(item);
                                        }}
                                        okText={"Có"}
                                        cancelText={"Không"}
                                      >
                                        <DeleteOutlined />
                                      </Popconfirm>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={"Xem trên FaceBook"}>
                                      <a href={`//facebook.com/${item.id}`}>
                                        <FacebookOutlined />
                                      </a>
                                    </Tooltip>
                                  </div>
                                )}
                                {item.isdelete ? <i>Bình luận đã bị xóa!</i> : ""}
                                {replyingTo && replyingTo.id === item.id && (
                                  <p className="blinking-text" style={{ marginTop: "5px" }}>
                                    Phản hồi bình luận...
                                  </p>
                                )}

                                {parentComment && (
                                  <div
                                    style={{
                                      backgroundColor: "#fafafa",
                                      padding: "10px",
                                      fontStyle: "italic",
                                      color: "#555",
                                    }}
                                  >
                                    <p style={{ fontSize: "12px", color: "#888", marginTop: "5px", fontStyle: "italic" }}>
                                      <strong>Phản hồi bình luận:</strong>{" "}
                                      <span>{parentComment.text != "" ? parentComment.text : parentComment.previewurl}</span>
                                    </p>
                                  </div>
                                )}
                                {item.previewurl && item.previewurl !== null && !item.is_zalo && (
                                  <Tooltip placement="top" title="Tải lại">
                                    {isreLoading ? (
                                      <div style={{ position: "absolute", right: "0px", display: "flex", alignItems: "center" }}>
                                        <Spin size="small" />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          position: "absolute",
                                          right: "0px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => ReloadFile(item)}
                                      >
                                        <InteractionTwoTone style={{ color: "#52647a", fontSize: "16px" }} />
                                      </div>
                                    )}
                                  </Tooltip>
                                )}
                              </span>
                            </Tooltip>
                            <div className="hover-buttons">
                              <Tooltip title="Chọn làm số điện thoại" placement="top">
                                <Avatar
                                  size={20}
                                  shape="square"
                                  style={{ backgroundColor: "#87d068", marginRight: "5px", marginLeft: "10px" }}
                                  icon={<PhoneFilled style={{ color: "white" }} />}
                                  onClick={() => funtionUpdatePhoneRightChat(item.phonenumber)}
                                />
                              </Tooltip>
                              <Tooltip title="Chọn làm địa chỉ" placement="top">
                                <Avatar
                                  size={20}
                                  shape="square"
                                  style={{ backgroundColor: "#ed182a", marginRight: "5px" }}
                                  icon={<EnvironmentFilled style={{ color: "white" }} />}
                                  onClick={() => funtionUpdateAddressRightChat(item.text)}
                                />
                              </Tooltip>
                              <Tooltip title="Thêm vào ghi chú" placement="top">
                                <Avatar
                                  size={20}
                                  shape="square"
                                  style={{ backgroundColor: "#1677ff", marginRight: "5px" }}
                                  icon={<NodeExpandOutlined style={{ color: "white" }} />}
                                  onClick={() => funtionAddNotesRightChat(item.text)}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </List.Item>
                      </>
                    );
                  }}
                />
              </InfiniteScroll>
            </div>
            <div className="input-icons">
              <Popover
                content={popoverContent}
                title="Gắn nhãn hội thoại"
                trigger="click"
                visible={isPopoverVisible}
                onVisibleChange={(visible) => setIsPopoverVisible(visible)}
              >
                <Tooltip title="Gắn nhãn" placement="top">
                  <Avatar size={30} style={{ backgroundColor: "white", margin: "5px" }} icon={<TagOutlined style={{ color: "black" }} />} />
                </Tooltip>
              </Popover>
              {filteredTags.map((tag) => {
                if (selectedTags.includes(tag.id.toString())) {
                  return (
                    <Tag key={tag.id} color={"#" + tag.color} onClick={() => handleTagClick(tag.id.toString())} style={{ cursor: "pointer" }}>
                      <CheckOutlined /> {tag.name}
                    </Tag>
                  );
                }
                return null;
              })}
            </div>
          </>
        )}

        <div className="chat-input-area">
          <div style={{ marginBottom: 8 }}>{renderPreviews()}</div>
          <div style={{ position: "relative", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <span
                style={{
                  position: "absolute",
                  top: "-8px",
                  left: "8px",
                  color: "#999",
                  pointerEvents: "none",
                }}
              >
                {replyingTo.id ? (
                  <p>
                    Phản hồi bình luận... <i>{replyingTo.text}</i>
                  </p>
                ) : (
                  <p>Nhập nội dung tin nhắn, nhấn [Enter] để gửi</p>
                )}
              </span>
              <textarea
                onChange={onTextChange}
                ref={textareaRef}
                onKeyPress={handleKeyPress}
                onPaste={handlePaste}
                style={{
                  flex: 1,
                  height: "100px",
                  resize: "none",
                  border: "none",
                  outline: "none",
                  padding: "8px",
                }}
              />
              <Button type="primary" loading={loadingchat} icon={<SendOutlined />} onClick={sendReplyComment} style={{ marginLeft: 8 }}>
                Gửi
              </Button>
            </div>
          </div>
          <div className="input-icons">
            <Tooltip title="Like">
              <Button icon={<LikeOutlined />} onClick={sendLikeIcon} type="text" />
            </Tooltip>
            <Tooltip title="Cảm xúc">
              <Button icon={<SmileOutlined />} onClick={openEmojiModal} type="text" />
            </Tooltip>
            <Tooltip title="Gửi ảnh">
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  uploadImage(file);
                  return false;
                }}
                accept="image/*"
              >
                <Button icon={<PictureOutlined />} type="text" />
              </Upload>
            </Tooltip>
            <Tooltip title="Tin nhắn nhanh">
              <Button icon={<SisternodeOutlined />} onClick={showModalTemplate} type="text" />
            </Tooltip>
            <Tooltip title="Thư viện ảnh">
              <Button icon={<ProductOutlined />} type="text" />
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal title="Chọn biểu tượng cảm xúc" visible={isEmojiModalVisible} onCancel={closeEmojiModal}>
        <Picker data={data} onEmojiSelect={addEmojiToText} />
      </Modal>
      <AddTagModal visible={isModalTagVisible} onCancel={handleCancelTag} onApply={handleTagOk} />
      {props.type !== "multiple" && <ModalMessage visible={isModalMessage} cmt={cmtsendMessage} closeModalMessage={closeModalMessage} />}
      <PostInfo visible={isShowPostInfo} selectedCmtPost={selectedCmtPost} close={closePostInfo} />
      <Template visible={isModalTemplateVisible} onCancel={handleCloseTemplate} onApply={applyTemplate} />
    </div>
  );
};
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export default DetailChat;
