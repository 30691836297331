import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import WorkerHistoryPrint from "./worker_history_print";
import Bill from "./bill";
import History from "./history";
import BillLive from "./bill_live";
import BillLiveOrder from "./bill_live_order";
import BillJATOrder from "./bill_jat_order";
import BillLive2 from "./bill_live2";

export const Print = React.forwardRef((props, ref) => {
  const componentRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    print() {
      if (props.beforePrint) props.beforePrint();
      handlePrint();
    },
  }));
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const afterPrint = () => {
    setTimeout(() => {
      if (props.afterPrint) props.afterPrint();
    }, 1000);
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onAfterPrint: afterPrint,
    removeAfterPrint: true,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        .red-text {
          color: red !important;
        }
      }
    `,
  });

  return (
    <div style={props.style}>
      <Button
        onClick={() => {
          if (props.beforePrint) props.beforePrint();
          handlePrint();
        }}
        size="large"
        type="primary"
        style={{ width: "100%" }}
      >
        Print
      </Button>
      <div style={{ display: "none" }}>
        {props.type == "bill" ? (
          <Bill
            ref={componentRef}
            lastTransaction={props.lastTransaction}
            payData={props.payData}
            lstPurchase={props.lstPurchase}
            lstReturn={props.lstReturn}
            header={props.header}
            customer={props.customer}
          />
        ) : (
          <></>
        )}
        {props.type == "history" ? <History listData={props.listData} header={props.header} ref={componentRef} /> : <></>}
        {props.type == "worker_history" ? <WorkerHistoryPrint listData={props.listData} header={props.header} ref={componentRef} /> : <></>}
        {props.type == "bill_live" ? <BillLive data={props.data} time={props.time} ref={componentRef} /> : <></>}
        {props.type == "bill_live2" ? <BillLive2 data={props.data} ref={componentRef} /> : <></>}
        {props.type == "bill_live_order" ? <BillLiveOrder data={props.data} ref={componentRef} details={props.details} /> : <></>}
        {props.type == "bill_jat_order" ? (
          <BillJATOrder
            data={props.data}
            cus={props.cus}
            config={props.config}
            shopAddres={props.shopAddres}
            customerAddres={props.customerAddres}
            ref={componentRef}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});
