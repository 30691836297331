import React, { useState } from "react";
import {
  Form,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Tag,
  Input,
  Space,
  Tabs,
  Modal,
  Select,
  Collapse,
  message,
  Skeleton,
  Empty,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  PhoneFilled,
  EnvironmentFilled,
  DownOutlined,
  DeleteOutlined,
  TruckOutlined,
  PrinterOutlined,
  ApiOutlined,
  FileProtectOutlined,
  SearchOutlined,
  SwapOutlined,
  FileTextOutlined,
  ShoppingCartOutlined,
  EnvironmentOutlined,
  UpCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { ChatData } from "../index";
import api from "api";
import "./style.css";
import CustomerChat from "./CustomerChat";
import { createSearchText } from "common/Text";
import AddressPicker from "component/live/address_picker";
import { formatCurrency, formatLongDate } from "common/Format2";
import JATCancelOrderPopup from "component/live/cancel_order_jat_popup";
import JATCreateOrderPopup from "component/live/jat_create_order_popup";
import ChangeSession from "component/live/popup_change_live_session";
import { Print } from "component/print";
import realtime from "common/Realtime";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { Panel } = Collapse;
const { Text, Title, Link } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;
const RightChat = () => {
  const [status, setStatus] = useState("1");
  const [selectedOrder, setSelectedOrder] = useState();
  const [lstOrder, setLstOrder] = React.useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [loadingCustomer, setLoadingCustomer] = React.useState(true);
  const [lstCustomer, setLstCustomer] = React.useState([]);
  const { selectedComment, setFuntionUpdatePhoneRightChat, setFuntionGetCustomerRightChat, customer, setCustomer, moveToComment } = React.useContext(ChatData);
  const [searchValue, setSearchValue] = React.useState("");
  const [lstSession, setLstSession] = React.useState([]);
  const [shopConfig, setShopConfig] = React.useState({});
  const [addressShop, setAddressShop] = React.useState({});
  const [selectedOrderPrint, setselectedOrderPrint] = React.useState({});
  const [lstDetail, setLstDetail] = React.useState([]);
  const [typePrint, setTypePrint] = React.useState();
  const [cusPrint, setCusPrint] = React.useState({});
  const [addresCus, setAddressCus] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [lstDiscount, setLstDiscount] = React.useState([]);
  const customerRef = React.useRef();
  const jatCreateOrderPopup = React.useRef();
  const jatCancelOrderPopup = React.useRef();
  const changeSession = React.useRef();
  const printRef = React.useRef();
  const previousSelectedComment = React.useRef({});
  const [form] = Form.useForm();

  const tabs = [
    {
      key: "1",
      label: "Khách hàng",
    },
    {
      key: "2",
      label: "Đơn hàng",
      disabled: customer == null,
    },
  ];

  React.useEffect(() => {
    api.get("ShopConfig/GetConfig").then((res) => {
      if (res.data) {
        api.get("Address/GetAddressByCode?code=" + res.data.shop_address_code).then((res2) => {
          setAddressShop(res2.data);
        });
        setShopConfig(res.data);
      }
    });
    realtime.subscribe("new_order", (noti) => {
      getLstOrder(customer?.id);
      getLstDiscount(customer?.id);
    });
    realtime.subscribe("customer_change", (noti) => {
      if (selectedComment.userid == noti) {
        if (customer) getCustomer(customer?.id);
        else getCustomer2();
      }
    });
  }, [customer]);

  React.useEffect(() => {
    if (customer != null) {
      form.setFieldsValue({
        customer_id: customer.id,
        customer_phone: customer.phone,
        customer_name: customer.name,
        customer_address_code: customer.address_code,
        customer_address: customer.address,
      });
    }
  }, [customer, loading]);

  React.useEffect(() => {
    if (previousSelectedComment.current?.id != selectedComment?.id) {
      setLoading(true);
      setSelectedOrder(null);
      getListCustomer();
      setActiveKey("1");
      getCustomer();
    }
    previousSelectedComment.current = selectedComment;
  }, [selectedComment]);

  React.useEffect(() => {
    if (!selectedOrder) return;
    getOrderByid();
  }, [selectedOrder]);

  React.useEffect(() => {
    setFuntionUpdatePhoneRightChat(() => handleSetPhone);
    setFuntionGetCustomerRightChat(() => getCustomer2);
  }, [setFuntionUpdatePhoneRightChat, customer, selectedComment]);

  React.useEffect(() => {
    getListSession();
  }, [activeKey]);

  const getCustomer2 = async () => {
    if (selectedComment != null) {
      var res = await api.get("CustomerLive/GetByFBUserID?id=" + selectedComment.userid);
      if (res.data) {
        setCustomer(res.data);
        getLstOrder(res.data.id);
        getLstDiscount(res.data.id);
        customerRef.current.setModeCustomerChat("info");
      } else {
        setCustomer(null);
      }
    } else {
      setCustomer(null);
    }
  };

  const getOrderByid = () => {
    api.get("LiveOrder/GetOrderInfo?id=" + selectedOrder.id).then((res) => {
      var data = {
        id: res.data.order.id,
        customer_name: res.data.cus ? res.data.cus.name : res.data.order.customer_name,
        customer_phone: res.data.cus?.phone,
        notes: res.data.order.notes,
        is_freeship: res.data.order.is_freeship,
        weight: res.data.order.weight,
        details: res.data.details,
        customer_id: res.data.order.customer_id,
        session_id: res.data.order.session_id,
      };
      form.setFieldsValue(data);
      setSearchValue(res.data.cus ? res.data.cus.name : res.data.order.customer_name);
    });
  };

  const getListSession = () => {
    api.get("LiveOrder/GetListSession").then((res) => {
      var data = res.lst.map((item) => {
        return {
          value: item.id,
          label:
            item.live_content && item.live_content != ""
              ? `${item.live_content}`
              : `Phiên live ${formatLongDate(item.start_time)} - ${item.end_time ? formatLongDate(item.end_time) : "Chưa kết thúc"}`,
          start_time: dayjs(item.start_time),
        };
      });
      setLstSession(data);
      if (selectedOrder == null) {
        form.setFieldsValue({ session_id: data[0].value });
      }
    });
  };

  const handleSetPhone = (phone) => {
    if (phone == null || phone == "") {
      message.error("Comment không có số điện thoại");
      return;
    }
    setActiveKey("1");
    setTimeout(() => {
      customerRef.current.setPhoneInForm(phone);
    }, 500);
  };

  const printBillJAT = (selectedOrder) => {
    api.get("CustomerLive/GetByID?id=" + selectedOrder.customer_id).then((res) => {
      selectedOrder.jat_respone = JSON.parse(selectedOrder.jat_create_order_respone);

      api.get("Address/GetAddressByCode?code=" + res.data.address_code).then((res2) => {
        setAddressCus(res2.data);
        setCusPrint(res.data);
        setselectedOrderPrint(selectedOrder);
        setTypePrint("bill_jat_order");
        setTimeout(() => {
          printRef.current.print();
        }, 1000);
      });
    });
  };

  const getCustomer = async (f) => {
    if (selectedComment != null) {
      var res = await api.get("CustomerLive/GetByFBUserID?id=" + selectedComment.userid);
      if (res.data) {
        setCustomer(res.data);
        getLstOrder(res.data.id);
        getLstDiscount(res.data.id);
      } else {
        setCustomer(null);
      }
    } else {
      setCustomer(null);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const getListCustomer = () => {
    api.get("CustomerLive/GetList").then((res) => {
      setLstCustomer(res.data);
      setLoadingCustomer(false);
    });
  };

  const getLstOrder = (id) => {
    if (id == null || id == "") {
      setLstOrder([]);
      return;
    }
    api.get(`LiveOrder/GetOrdersFromCustomerID?id=${id}&updateStatus=${true}`).then((res) => {
      setLstOrder(res.data);
    });
  };

  const getLstDiscount = (id) => {
    if (id == null || id == "") {
      setLstDiscount([]);
      return;
    }
    api.get(`Discount/GetByCustomerID?id=${id}`).then((res) => {
      setLstDiscount(res.data);
    });
  };

  const saveOrderConfirm = (cus, values) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Số điện thoại ${values.customer_phone} là của khách hàng ${cus.name}. Bạn có muốn thay đổi đơn hàng qua khách này không?`,
      onOk() {
        saveOrder(values);
      },
      onCancel() {},
    });
  };

  const saveOrder = (values) => {
    api.post("LiveOrder/SaveEditOrder", values).then((res) => {
      message.success("Lưu thành công");
      if (res.data.customer_id != customer.id) {
        setSelectedOrder(null);
        form.setFieldsValue({ id: null, session_id: null, details: [], weight: null, notes: null, is_freeship: false });
      }
      getOrderByid();
    });
  };

  const onFinish = (values) => {
    if (!values.details || values.details.length == 0) {
      message.error("Vui lòng nhập sản phẩm");
      return;
    }
    if (!selectedComment.is_zalo) values.fb_user_id = selectedComment?.userid;
    else values.zalo_user_id = selectedComment?.userid;
    if (values.customer_id) {
      var cus = lstCustomer.find((x) => x.id == values.customer_id);
      if (cus != null) values.customer_name = cus?.name;
    }
    values.customer_id = customer.id;
    if (values.id && values.id != 0)
      if (values.customer_phone && values.customer_phone != "") {
        api
          .get(`CustomerLive/CheckPhoneOrder?id=${values.customer_id}&phone=${values.customer_phone}`)
          .then((res) => {
            if (res.cus) {
              saveOrderConfirm(res.cus, values);
            } else {
              saveOrder(values);
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      } else {
        saveOrder(values);
      }
    else
      api.post("LiveOrder/SaveNewOrder", values).then((res) => {
        message.success("Lưu thành công");

        form.setFieldsValue({ id: null, session_id: null, details: [], weight: null, notes: null, is_freeship: false });
      });

    setTimeout(() => {
      getCustomer();
    }, 500);
  };

  const onTabChange = (e) => {
    if (e == "2") getListSession();
    else {
      setSelectedOrder(null);
      form.setFieldsValue({ id: null, session_id: null, details: [], weight: null, notes: null, is_freeship: false });
    }
    setActiveKey(e);
  };

  return (
    <div style={{ height: "100%", border: "1px solid #dfdfdf", borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
      {!selectedComment ? (
        <div style={{ height: "100%", display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <Empty />
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <div style={{ padding: 10, backgroundColor: "#fff" }}>
              <Skeleton />
            </div>
          ) : (
            <div style={{ marginBottom: 16 }} className="right-chat">
              <Tabs
                activeKey={activeKey}
                centered
                onChange={(e) => {
                  onTabChange(e);
                }}
                items={tabs}
                style={{ background: "#fff" }}
              ></Tabs>
              {activeKey == "1" ? (
                <CustomerChat
                  ref={customerRef}
                  customer={customer}
                  setCustomer={setCustomer}
                  getLstOrder={getLstOrder}
                  getLstDiscount={getLstDiscount}
                  lstOrder={lstOrder}
                  lstDiscount={lstDiscount}
                  shopConfig={shopConfig}
                  addressShop={addressShop}
                  setSelectedOrder={setSelectedOrder}
                  onTabChange={onTabChange}
                  getCustomer={getCustomer}
                />
              ) : (
                <div style={{ padding: 10, backgroundColor: "#fff" }}>
                  {selectedOrder ? (
                    <div style={{ marginBottom: 20 }}>
                      <Row gutter={[20, 10]}>
                        <Col span={12} style={{ fontWeight: "bold" }}>
                          <span>Mã đơn hàng:</span>&nbsp; <span style={{ color: "orange" }}>{selectedOrder.id}</span>
                        </Col>
                        <Col span={12}>
                          <span style={{ fontWeight: "bold" }}>Trạng thái J&T:</span>&nbsp;{" "}
                          <span>
                            {selectedOrder.transpost_status == "created" ? (
                              <Tag color="green">Đã gửi đơn</Tag>
                            ) : (
                              <>
                                {selectedOrder.transpost_status == "cancelled" ? (
                                  <Tag color="orange">Đã hủy đơn</Tag>
                                ) : (
                                  <>
                                    <Tag color="blue-inverse">Chưa gửi đơn</Tag>
                                  </>
                                )}
                              </>
                            )}
                          </span>
                        </Col>

                        <Col span={12}>
                          <span style={{ fontWeight: "bold" }}>Người tạo:</span>&nbsp; <span>{selectedOrder.create_by}</span>
                        </Col>

                        <Col span={12}>
                          <span style={{ fontWeight: "bold" }}>Mã vận đơn:</span>&nbsp;{" "}
                          <span>{selectedOrder.transpost_id ? selectedOrder.transpost_id : "Chưa có"}</span>
                        </Col>

                        <Col span={12}>
                          <span style={{ fontWeight: "bold" }}>Ngày tạo :</span>&nbsp; <span>{formatLongDate(selectedOrder.create_time)}</span>
                        </Col>

                        <Col span={12}>
                          <div style={{ display: "flex", gap: 20 }}>
                            <Button
                              type={"primary"}
                              onClick={async () => {
                                var data = await api.get("LiveOrder/GetListOrderDetail?id=" + selectedOrder.id);
                                if (data.code == 1) setLstDetail(data.data);
                                setselectedOrderPrint(selectedOrder);
                                setTypePrint("bill_live_order");
                                setTimeout(() => {
                                  printRef.current.print();
                                }, 500);
                              }}
                              icon={<PrinterOutlined />}
                            ></Button>
                            <Button
                              type={"primary"}
                              onClick={() => {
                                changeSession.current.open(selectedOrder.id);
                              }}
                              icon={<SwapOutlined />}
                            ></Button>
                            {selectedOrder.customer_phone &&
                            selectedOrder.customer_address &&
                            selectedOrder.customer_address_code &&
                            selectedOrder.transpost_status != "created" ? (
                              <Button
                                type={"primary"}
                                onClick={() => {
                                  jatCreateOrderPopup.current.open(selectedOrder.id);
                                }}
                                icon={<TruckOutlined />}
                              />
                            ) : (
                              <></>
                            )}
                            {selectedOrder.transpost_status == "created" ? (
                              <>
                                <Button
                                  icon={<ApiOutlined />}
                                  type={"primary"}
                                  onClick={() => {
                                    jatCancelOrderPopup.current.open(selectedOrder.id);
                                  }}
                                ></Button>
                                <Button
                                  icon={<FileProtectOutlined />}
                                  type={"primary"}
                                  onClick={() => {
                                    if (!selectedOrder.customer_id) {
                                      message.error("Dữ liệu đã cũ.");
                                      return;
                                    }
                                    printBillJAT(selectedOrder);
                                  }}
                                ></Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </div>
                  ) : (
                    <></>
                  )}
                  <Form
                    labelCol={{
                      flex: "110px",
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                      flex: 1,
                    }}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{
                      maxWidth: 600,
                    }}
                  >
                    <Form.Item name={"id"} label="Mã đơn hàng">
                      <Input disabled={true} style={{ fontWeight: "bold", color: "orange" }}></Input>
                    </Form.Item>
                    <Form.Item name={"session_id"} label="Phiên live">
                      <Select placeholder={"chọn phiên live"} options={lstSession} disabled={selectedOrder} allowClear />
                    </Form.Item>
                    <Form.Item hidden={true} name={"customer_name"} />
                    <Form.Item
                      name="customer_id"
                      label="Khách hàng"
                      required={true}
                      rules={[
                        {
                          validator: (rule, value) => {
                            var data = form.getFieldsValue();
                            return new Promise((resolve, error) => {
                              if (data.customer_id == null && (data.customer_name == null || data.customer_name == "")) {
                                error();
                              } else {
                                resolve();
                              }
                            });
                          },
                          message: "Vui lòng nhập tên hoặc chọn khách hàng!",
                        },
                      ]}
                    >
                      <Select
                        loading={loadingCustomer}
                        style={{
                          width: "100%",
                        }}
                        searchValue={form.getFieldValue("customer_name")}
                        onChange={(e, f) => {
                          if (f != null) {
                            form.setFieldsValue({ customer_phone: f.phone, customer_address_code: f.address_code, address: f.address, customer_id: f.id });
                            setSearchValue(f.name);
                          } else {
                            form.setFieldsValue({ customer_phone: null, customer_address_code: null, customer_name: "", address: null, customer_id: null });
                          }
                          setTimeout(() => {
                            setSearchValue(Math.random().toString());
                          }, 500);
                        }}
                        options={lstCustomer}
                        fieldNames={{ label: "name", value: "id" }}
                        showSearch={true}
                        allowClear={true}
                        autoClearSearchValue={true}
                        onSearch={(e) => {
                          setSearchValue(e);
                          if (e != null && e != "") {
                            form.setFieldsValue({ customer_name: e });
                          }
                          form.validateFields(["customer_id"]);
                        }}
                        filterOption={(key, option) => {
                          if (key == "") return true;
                          key = createSearchText(key);
                          var label = createSearchText(option.name);
                          if (label.includes(key)) return true;
                          return false;
                        }}
                        virtual={false}
                      />
                    </Form.Item>
                    {/* <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.customer_name !== currentValues.customer_name}>
                      {({ getFieldValue }) => {
                        return <div>{getFieldValue("customer_name")}</div>;
                      }}
                    </Form.Item> */}
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.customer_id !== currentValues.customer_id}>
                      {({ getFieldValue }) => {
                        return (
                          <Form.Item name="customer_phone" label="Số điện thoại">
                            <Input style={{ textAlign: "right" }} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.customer_phone !== currentValues.customer_phone}>
                      {({ getFieldValue }) => {
                        return getFieldValue("customer_phone") !== "" && getFieldValue("customer_phone") != null ? (
                          <>
                            <Form.Item name={"customer_address_code"} label={"Địa chỉ"}>
                              <AddressPicker />
                            </Form.Item>
                            <Form.Item name="customer_address">
                              <Input placeholder="Địa chỉ" />
                            </Form.Item>
                          </>
                        ) : null;
                      }}
                    </Form.Item>
                    <div style={{ display: "flex", gap: 50 }}>
                      <Form.Item name="is_freeship" valuePropName="checked">
                        <Checkbox>Freeship</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={"weight"}
                        label="Cân nặng"
                        layout="horizontal"
                        style={{ flex: 1 }}
                        labelCol={{
                          flex: "80px",
                        }}
                      >
                        <InputNumber min={0} suffix="KG" />
                      </Form.Item>
                    </div>

                    <Form.Item name="notes" label="Ghi chú">
                      <TextArea />
                    </Form.Item>
                    <Divider>Sản Phẩm</Divider>
                    <Form.List name={"details"}>
                      {(fields, { add, remove }, { errors }) => {
                        return (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Space style={{ marginBottom: 5 }}>
                                <Form.Item name={"cmt_id"} hidden></Form.Item>
                                <Form.Item
                                  name={[name, "product"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Nhập mã sản phẩm",
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={form.getFieldValue("details")[index] ? true : false}
                                    placeholder="Mã SP"
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={[name, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Nhập số lượng",
                                    },
                                  ]}
                                >
                                  <InputNumber min={1} />
                                </Form.Item>
                                <Form.Item
                                  name={[name, "price"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Nhập giá",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                                <div style={{ display: "flex", gap: 10, width: 50 }}>
                                  <Form.Item>
                                    <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                                  </Form.Item>
                                  {form.getFieldValue("details")[index]?.cmt_id ? (
                                    <Form.Item>
                                      <UpCircleOutlined
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          moveToComment(form.getFieldValue("details")[index]?.cmt_id);
                                        }}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} style={{ marginTop: 15 }} block icon={<PlusOutlined />}>
                                Thêm sản phẩm
                              </Button>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  </Form>
                  <div
                    style={{
                      position: "sticky",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: "16px",
                      background: "#ffffff",
                      boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
                      zIndex: 999,
                      zIndex: 10,
                      background: "#fff",
                    }}
                  >
                    <Row justify="space-between">
                      <Col>
                        <Button
                          icon={<SaveOutlined />}
                          type="primary"
                          onClick={() => {
                            form.submit();
                          }}
                        >
                          Lưu
                        </Button>
                        <Button
                          style={{ marginLeft: "8px" }}
                          icon={<FileTextOutlined />}
                          onClick={() => {
                            setSelectedOrder(null);
                            form.setFieldsValue({ id: null, session_id: lstSession[0].value, details: [], weight: null, notes: null, is_freeship: false });
                          }}
                        >
                          Tạo mới
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <JATCreateOrderPopup ref={jatCreateOrderPopup} reload={getLstOrder} printBillJAT={printBillJAT}></JATCreateOrderPopup>
      <JATCancelOrderPopup ref={jatCancelOrderPopup} reload={getLstOrder}></JATCancelOrderPopup>
      <ChangeSession ref={changeSession} reload={getLstOrder} />
      <div style={{ display: "none" }}>
        <Print
          type={typePrint}
          data={selectedOrderPrint}
          cus={cusPrint}
          config={shopConfig}
          shopAddres={addressShop}
          customerAddres={addresCus}
          details={lstDetail}
          ref={printRef}
        />
      </div>
    </div>
  );
};

export default RightChat;
