import React, { Children } from "react";
import { Modal, Timeline, message } from "antd";
import api from "api";
import { formatLongDate } from "common/Format2";

const TrackingPopup = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [lstData, setLstData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    open(id) {
      api
        .get("LiveOrder/GetTrackingByOrderID?id=" + id)
        .then((res) => {
          setLstData(res.data);
          setVisible(true);
        })
        .catch((err) => {
          message.error(err.message);
        });
    },
  }));
  return (
    <Modal
      title="Hành trình vận chuyển"
      footer={[]}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      width={"60%"}
    >
      <Timeline
        items={lstData.map((x) => {
          return {
            children: (
              <div>
                <div>{formatLongDate(x.Scantime)}</div>
                <div>{x.Scantype}</div>
                <div>{x.Desc}</div>
              </div>
            ),
          };
        })}
      />
    </Modal>
  );
});
export default TrackingPopup;
