import React from "react";
import { Column } from "@ant-design/charts";
import api from "api";
import dayjs from "dayjs";
import { DatePicker, Card, Statistic, Table, Row, Col, Button, Select, Tag } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import { formatCurrency } from "common/Format2";
import { forEach, groupBy } from "lodash-es";
import { useParams, useNavigate } from "react-router-dom";
import AntTable from "component/common/AntTable";

const Report = () => {
  const [startDateThuChi, setStartDateThuChi] = React.useState(dayjs().subtract(1, "month"));
  const [endDateThuChi, setEndDateThuChi] = React.useState(dayjs());
  const [startDateCustomer, setStartDateCustomer] = React.useState(dayjs().subtract(1, "month"));
  const [endDateCustomer, setEndDateCustomer] = React.useState(dayjs());
  const [startDateSale, setStartDateSale] = React.useState(dayjs().date(1));
  const [endDateSale, setEndDateSale] = React.useState(dayjs());
  const [startDateReturn, setStartDateReturn] = React.useState(dayjs().date(1));
  const [endDateReturn, setEndDateReturn] = React.useState(dayjs());
  const [startDateDebt, setStartDateDebt] = React.useState(dayjs().date(1));
  const [endDateDebt, setEndDateDebt] = React.useState(dayjs());
  const [startDateRevenue, setStartDateRevenue] = React.useState(dayjs().date(1));
  const [endDateRevenue, setEndDateRevenue] = React.useState(dayjs());
  const [startDateImport, setStartDateImport] = React.useState(dayjs().date(1));
  const [endDateImport, setEndDateImport] = React.useState(dayjs());
  const [startDateCustomerStrength, setStartDateCustomerStrength] = React.useState(dayjs().date(1));
  const [endDateCustomerStrength, setEndDateCustomerStrength] = React.useState(dayjs());
  const [dataThuChi, setDataThuChi] = React.useState({});
  const [dataCustomer, setDataCustomer] = React.useState({});
  const [totalSale, setTotalSale] = React.useState(0);
  const [totalDebt, setTotalDebt] = React.useState(0);
  const [totalReturn, setTotalReturn] = React.useState(0);
  const [totalRevenue, setTotalRevenue] = React.useState(0);
  const [totalImport, setTotalImport] = React.useState(0);
  const tbRevenue = React.useRef();
  const tbImport = React.useRef();
  const tbSale = React.useRef();
  const tbReturn = React.useRef();
  const tbDebt = React.useRef();
  const tbCustomerStrength = React.useRef();
  const navigate = useNavigate();
  const optionDate = [
    {
      label: "Hôm nay",
      value: 1,
      data: [dayjs(), dayjs()],
    },
    {
      label: "7 ngày trước",
      value: 2,
      data: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Tuần này",
      value: 3,
      data: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Tháng trước",
      value: 4,
      data: [dayjs().add(-1, "month").date(1), dayjs().add(-1, "month").endOf("month")],
    },
    {
      label: "Tháng này",
      value: 5,
      data: [dayjs().date(1), dayjs().endOf("month")],
    },
    {
      label: "Năm này",
      value: 6,
      data: [dayjs().month(0).date(1), dayjs().endOf("month")],
    },
  ];

  React.useEffect(() => {
    if (startDateThuChi != null && endDateThuChi != null) {
      getDataThuChi();
    }
  }, [startDateThuChi, endDateThuChi]);

  React.useEffect(() => {
    if (startDateCustomer != null && endDateCustomer != null) {
      getDataCustomer();
    }
  }, [startDateCustomer, endDateCustomer]);

  React.useEffect(() => {
    if (startDateRevenue != null && endDateRevenue != null) {
      if (tbRevenue.current.reload) tbRevenue.current.reload();
    }
  }, [startDateRevenue, endDateRevenue]);
  React.useEffect(() => {
    if (startDateSale != null && endDateSale != null) {
      if (tbSale.current.reload) tbSale.current.reload();
    }
  }, [startDateSale, endDateSale]);
  React.useEffect(() => {
    if (startDateReturn != null && endDateReturn != null) {
      if (tbReturn.current.reload) tbReturn.current.reload();
    }
  }, [startDateReturn, endDateReturn]);
  React.useEffect(() => {
    if (startDateDebt != null && endDateDebt != null) {
      if (tbDebt.current.reload) tbDebt.current.reload();
    }
  }, [startDateDebt, endDateDebt]);
  React.useEffect(() => {
    if (startDateImport != null && endDateImport != null) {
      if (tbImport.current.reload) tbImport.current.reload();
    }
  }, [startDateImport, endDateImport]);
  React.useEffect(() => {
    if (startDateCustomerStrength != null && endDateCustomerStrength != null) {
      if (tbCustomerStrength.current.reload) tbCustomerStrength.current.reload();
    }
  }, [startDateCustomerStrength, endDateCustomerStrength]);

  const getDataThuChi = () => {
    api.post("Report/ReportThuChi", { StartTime: startDateThuChi.format(), EndTime: endDateThuChi.format() }).then((res) => {
      setDataThuChi(res.data);
    });
  };

  const getDataCustomer = () => {
    api.post("Report/CustomerSaleReport", { StartTime: startDateCustomer.format(), EndTime: endDateCustomer.format() }).then((res) => {
      var lst = [...res.lstWholeSale, ...res.lstCustomer];
      lst = lst.map((x) => {
        return { month: x.month + "/" + x.year, type: x.type, value: Math.abs(x.total) };
      });
      setDataCustomer(lst);
    });
  };

  const col = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Loại",
      dataIndex: "is_wholesale",
      render: (text, record, index) => {
        if (record.is_wholesale) return <Tag color="#87d068">Sỉ</Tag>;
        else return <Tag color="#108ee9">Lẻ</Tag>;
      },
    },
    {
      title: "Tổng tiền",
      dataIndex: "total",
      render: (text, record, index) => {
        return formatCurrency(Math.abs(record.total));
      },
    },
  ];

  const colImport = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (text, record, index) => {
        if (record.type == "worker") return <Tag color="#87d068">Thợ</Tag>;
        if (record.type == "supplier") return <Tag color="#108ee9">Đầu vào</Tag>;
      },
    },
    {
      title: "Tổng tiền",
      dataIndex: "total",
      render: (text, record, index) => {
        return formatCurrency(Math.abs(record.total));
      },
    },
  ];

  const colDebt = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tên",
      dataIndex: "customer_name",
    },
    {
      title: "Số tiền",
      dataIndex: "profix",
      render: (text, record, index) => {
        return formatCurrency(record.profit);
      },
    },
  ];

  const colStrength = [
    {
      title: "Stt",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Tháng",
      render: (text, record, index) => {
        return record.month + "/" + record.year;
      },
    },
    {
      title: "Tên",
      dataIndex: "name",
    },
    {
      title: "Lấy hàng",
      dataIndex: "total_purchase",
      render: (text, record, index) => {
        return formatCurrency(record.total_purchase);
      },
    },
    {
      title: "Trả hàng",
      dataIndex: "total_return",
      render: (text, record, index) => {
        return formatCurrency(record.total_return);
      },
    },
    {
      title: "Tỉ lệ",
      render: (text, record, index) => {
        if (record.total_return != 0 && record.total_purchase != 0) {
          return Math.floor((record.total_return / record.total_purchase) * 10000) / 100 + "%";
        } else {
          if (record.total_return == 0 && record.total_purchase == 0) return;
          if (record.total_return == 0) return "0%";
          if (record.total_purchase == 0) return "100%";
        }
      },
    },
    {
      title: "Xếp hạng",
      dataIndex: "total_purchase",
      render: (text, record, index) => {
        var percent = 0;
        if (record.total_return != 0 && record.total_purchase != 0) {
          percent = Math.floor((record.total_return / record.total_purchase) * 10000) / 100;
        } else {
          if (record.total_return == 0 && record.total_purchase == 0) return;
          if (record.total_return == 0) percent = 0;
          if (record.total_purchase == 0) percent = 100;
        }
        if (percent <= 5) return <Tag color="#87d068">Hoàn hảo</Tag>;
        if (percent <= 10) return <Tag color="#108ee9">Tốt</Tag>;
        if (percent <= 25) return <Tag color="#2db7f5">Bình thường</Tag>;
        if (percent > 25) return <Tag color="#f50">Không tốt</Tag>;
      },
    },
  ];

  const config = {
    data: dataCustomer,
    xField: "month",
    yField: "value",
    group: true,
    colorField: "type",
    label: {
      text: "value",
      textBaseline: "bottom",
      position: "top",
      formatter: (datum, index, data, Vector) => {
        return formatCurrency(datum);
      },
      fill: "#000",
    },
    axis: {
      x: {},
      y: {
        labelFormatter: (datum, index, data, Vector) => {
          return formatCurrency(datum);
        },
      },
    },
    scale: { color: { palette: "tableau10" } },
  };

  return (
    <div>
      <Card title="Báo cáo thu chi" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
          <div style={{ flex: 1 }}>
            <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDateThuChi} value={startDateThuChi} />
          </div>
          <div style={{ display: "flex", margin: 5 }}>
            <div style={{ margin: "auto" }}>Đến</div>
          </div>
          <div style={{ flex: 1 }}>
            <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDateThuChi} value={endDateThuChi} />
          </div>
        </div>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic title="Thu" value={Math.abs(dataThuChi.Thu)} prefix={<DollarOutlined />} />
          </Col>
          <Col span={12}>
            <Statistic title="Chi" value={Math.abs(dataThuChi.Chi)} prefix={<DollarOutlined />} />
          </Col>
        </Row>
      </Card>
      <Card title="Báo cáo doanh thu theo loại khách hàng" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
          <div style={{ flex: 1 }}>
            <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDateCustomer} value={startDateCustomer} />
          </div>
          <div style={{ display: "flex", margin: 5 }}>
            <div style={{ margin: "auto" }}>Đến</div>
          </div>
          <div style={{ flex: 1 }}>
            <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDateCustomer} value={endDateCustomer} />
          </div>
        </div>
        <Column {...config} scrollbar={dataCustomer.length > 10 ? { x: {} } : {}} />
      </Card>
      <Card title="Báo cáo doanh thu" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            size="large"
            defaultValue={5}
            style={{ width: 200, marginBottom: 10 }}
            options={optionDate}
            onChange={(e, obj) => {
              setStartDateRevenue(obj.data[0]);
              setEndDateRevenue(obj.data[1]);
            }}
          />
        </div>
        <AntTable
          columns={col}
          ajax="Report/RevenueReport"
          ref={tbRevenue}
          customSearch={{
            StartTime: startDateRevenue,
            EndTime: endDateRevenue,
          }}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalRevenue)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalRevenue(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card title="Báo cáo bán hàng" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            size="large"
            defaultValue={5}
            style={{ width: 200, marginBottom: 10 }}
            options={optionDate}
            onChange={(e, obj) => {
              setStartDateSale(obj.data[0]);
              setEndDateSale(obj.data[1]);
            }}
          />
        </div>
        <AntTable
          columns={col}
          ajax="Report/SaleReport"
          ref={tbSale}
          customSearch={{
            StartTime: startDateSale,
            EndTime: endDateSale,
          }}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalSale)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalSale(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card title="Báo cáo trả hàng" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            size="large"
            defaultValue={5}
            style={{ width: 200, marginBottom: 10 }}
            options={optionDate}
            onChange={(e, obj) => {
              setStartDateReturn(obj.data[0]);
              setEndDateReturn(obj.data[1]);
            }}
          />
        </div>
        <AntTable
          columns={col}
          ajax="Report/ReturnReport"
          ref={tbReturn}
          customSearch={{
            StartTime: startDateReturn,
            EndTime: endDateReturn,
          }}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalReturn)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalReturn(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card title="Báo cáo công nợ" style={{ marginBottom: 20 }}>
        <AntTable
          columns={colDebt}
          ajax="Report/ReportCustomerDebt"
          ref={tbDebt}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalDebt)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalDebt(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card title="Báo cáo nhập hàng" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            defaultValue={5}
            style={{ width: 200, marginBottom: 10 }}
            options={optionDate}
            onChange={(e, obj) => {
              setStartDateImport(obj.data[0]);
              setEndDateImport(obj.data[1]);
            }}
          />
        </div>
        <AntTable
          size="large"
          columns={colImport}
          ajax="Report/ImportReport"
          ref={tbImport}
          customSearch={{
            StartTime: startDateImport,
            EndTime: endDateImport,
          }}
          title={() => <div style={{ fontWeight: "bold", fontSize: 13 }}> {`Tổng: ${formatCurrency(totalImport)}`} </div>}
          onGetDataFinish={(res) => {
            setTotalImport(res.sum ?? 0);
          }}
        ></AntTable>
      </Card>
      <Card title="Báo cáo độ mạnh khách hàng" style={{ marginBottom: 20 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            size="large"
            style={{ width: 200, marginBottom: 10 }}
            defaultValue={5}
            options={[
              {
                label: "Tháng trước",
                value: 4,
                data: [dayjs().add(-1, "month").date(1), dayjs().add(-1, "month").endOf("month")],
              },
              {
                label: "Tháng này",
                value: 5,
                data: [dayjs().date(1), dayjs().endOf("month")],
              },
              {
                label: "Năm này",
                value: 6,
                data: [dayjs().month(0).date(1), dayjs().endOf("month")],
              },
            ]}
            onChange={(e, obj) => {
              setStartDateCustomerStrength(obj.data[0]);
              setEndDateCustomerStrength(obj.data[1]);
            }}
          />
        </div>
        <AntTable
          columns={colStrength}
          ajax="Report/CustomerStrengthReport"
          ref={tbCustomerStrength}
          customSearch={{
            StartTime: startDateCustomerStrength,
            EndTime: endDateCustomerStrength,
          }}
        ></AntTable>
      </Card>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
      </div>
    </div>
  );
};

export default Report;
