import React, { useState, useEffect } from "react";
import { Tooltip, Modal, Button, Input, Checkbox, Layout, Empty, message, Tabs, Skeleton, Badge, Avatar } from "antd";
import api from "api";
import {
  SearchOutlined,
  SyncOutlined,
  FilterOutlined,
  SettingOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  TagOutlined,
  EllipsisOutlined,
  PhoneFilled,
  EnvironmentFilled,
  NodeExpandOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import "./style.css";
import ListComment from "./list_comment";
import moment from "moment";
import DetailChat from "./detail_chat";
import { ChatData } from "./index";
import SidebarMenu from "./sidebar";
import realtime from "common/Realtime";
import useStateCallback from "component/common/useStateCallBack";
import axios from "axios";
import { zaloUrl } from "config";
import { createSearchText } from "common/Text";

const { Sider, Content } = Layout;
const Chat = () => {
  const [objectSearch, setObjectSearch] = useStateCallback({
    pageSelect: [],
    typemessage: "",
    haveread: "",
    havereply: "",
    havephone: "",
    begintime: "",
    endtime: "",
    post: "",
    tag: "",
    search: "",
    pagging: 1,
    curentView: 0,
    userid: "",
    assignto: "",
    beginhour: "00:00",
    endhour: "23:59",
    customergroupid: [],
    interactivecustomer: [],
    haveorder: "",
    customertype: "",
    zaloFilter: false,
    facebookFilter: false,
  });
  const [isSearching, setIsSearching] = React.useState(false);
  const [lstFriends, setLstFriends] = useStateCallback([]);
  const [lstFriendsDisplay, setLstFriendsDisplay] = useStateCallback([]);
  const [loadingFriend, setLoadingFriend] = useStateCallback(false);
  const [lstComment, setLstComment] = useStateCallback([]);
  const [lstCommentChange, setLstCommentChange] = React.useState([]);
  const [checkSearchByContent, setCheckSearchByContent] = React.useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const { selectedComment, setSelectedComment } = React.useContext(ChatData);
  const [check, setShowCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [visibleChat, setVisibleChat] = useState(false);
  const [isEnd, SetIsEnd] = useState(false);
  const [zaloSearchText, setZaloSearchText] = useState("");
  const [userSearch, setUserSearch] = useState();
  const [height, setHeight] = useState(100);
  const indeterminate = checkedItems.length > 0 && checkedItems.length < lstComment.length;
  const checkAll = lstComment.length === checkedItems.length;
  const lstFriendID = lstFriends.map((y) => y.userId);
  const checkAllFriend = lstFriends.length == checkedItems.filter((x) => lstFriendID.includes(x.id)).length;
  const indeterminateFriend =
    lstFriends.length > checkedItems.filter((x) => lstFriendID.includes(x.id)).length && checkedItems.filter((x) => lstFriendID.includes(x.id)).length > 0;
  const tabs = [
    {
      key: "1",
      label: "Chat",
    },
    {
      key: "2",
      label: "Bạn bè Zalo",
    },
  ];

  const onTabChange = (e) => {
    if (e == "2") getListFriend();
    setActiveKey(e);
  };

  const getListFriend = () => {
    axios
      .request({
        method: "get",
        url: zaloUrl + "get-all-user",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res) {
          setLstFriends(res.data.data);
          setLstFriendsDisplay(res.data.data);
        }
      })
      .catch();
  };

  realtime.subscribe("comment_changed", (data) => {
    console.log("comment_changed");
    try {
      const updatedComments = lstComment;
      const index = updatedComments.findIndex((e) => e.id == data.id);
      if (index >= 0) {
        updatedComments[index] = data;
        setLstComment(updatedComments);
      }
    } catch (error) {}
  });
  realtime.subscribe("comment_added", (data) => {
    console.log("comment_added");
    onCommentChanged(data);
  });

  const onCommentChanged = (data) => {
    if (!lstCommentChange) {
      lstCommentChange = [];
    }
    lstCommentChange.push(data);
    processCommentChanged();
  };
  const filter = (comment, parentComment) => {
    var search = objectSearch;
    if (search.search != "") {
      if (!search.search.includes(parentComment.userid)) {
        return false;
      }
    }

    if (search.pageSelect.length > 0 && search.pageSelect != undefined && search.pageSelect != null) {
      var index = search.pageSelect.findIndex((item) => {
        return item == parentComment.pageid;
      });

      if (index < 0) {
        return false;
      }
    }

    if (search.typemessage != "" && parentComment.typeof != search.typemessage) {
      return false;
    }

    if (search.haveread != "") {
      if (search.haveread == "unread") {
        if (parentComment.isread === true) {
          return false;
        }
      }
    }
    if (search.haveread != "") {
      //đang lọc đã đọc
      if (search.haveread == "read") {
        if (parentComment.isread !== true) {
          return false;
        }
      }
    }

    if (search.havereply != "") {
      //đang lọc đã trả lời
      var kt = search.havereply == "reply" ? true : false;
      if (parentComment.isreply != kt) {
        return false;
      }
    }

    var phone = parentComment.phone ? parentComment.phone : "";
    if (search.havephone == "nophone") {
      if (phone != "") {
        return false;
      }
    }

    if (search.havephone == "havephone") {
      //đang lọc  có sdt
      if (phone == "") {
        return false;
      }
    }

    if (search.begintime != "") {
      var kt = moment(parentComment.lastupdatetime).isBetween(search.begintime, search.endtime);
      if (!kt) {
        return false;
      }
    }

    if (search.userid != "") {
      if (parentComment.userid != search.userid) {
        return false;
      }
    }
    if (search.tag != "" && search.tag != undefined && search.tag != null) {
      var lstTag1 = search.tag.split(",");
      var lstTag = lstTag1.filter((x) => x !== "");

      if (parentComment.tag == null || parentComment.tag == undefined) {
        parentComment.tag = "";
      }
      var array = parentComment.tag.split(",");
      var index = array.findIndex((item) => {
        return item == lstTag.find((x) => x == item);
      });
      if (index < 0) {
        return false;
      }
    }

    return true;
  };
  const setReadedComment = () => {
    const item = selectedComment;
    var dataToPost = { ID: item.id, Type: item.typeof };
    api.post("Facebook/UpdateRead", dataToPost).then((data) => {});
  };
  const processCommentChanged = () => {
    if (!lstCommentChange || lstCommentChange.length === 0) {
      return;
    }

    let lstChanged = [...lstCommentChange];
    let selected = { ...selectedComment };
    let lst = [...lstComment];

    if (objectSearch.pagging === 1 && lst.length > 50) {
      lst = lst.slice(0, 50);
    }
    for (let i = 0; i < lstChanged.length; i++) {
      const data = lstChanged[i];
      const parentComment = data.parentcomment;
      if (!parentComment) continue;

      parentComment.childtext = processChildText(parentComment.childtext);
      const childComment = data.childcomment;

      const index = lst.findIndex((item) => item.id === parentComment.id);

      if (index >= 0) {
        if (selected.id === parentComment.id) {
          parentComment.isread = true;
          parentComment.unreadcount = 0;
          setReadedComment();
        }
        lst[index] = parentComment;
      } else {
        if (childComment) {
          if (filter(childComment, parentComment)) {
            lst.unshift(parentComment);
          }
        }
      }

      if (selected.id === parentComment.id) {
        setSelectedComment(selected);
      }
    }

    const lstSort = getSortList(lst);
    setLstComment(lstSort);
    setLstCommentChange([]);
  };

  const getListFBComment = () => {
    SetIsEnd(false);
    setIsSearching(true);
    setObjectSearch((prev) => ({ ...prev, pagging: 1 }));
    setLstComment([]);
    setSelectedComment({});
  };

  useEffect(() => {
    if (isSearching) {
      api.post("Facebook/GetListFBComment", { ...objectSearch, checkSearchByContent }).then((res) => {
        addListComment(res.data, true);
        setIsSearching(false);
        if (res.data.length === 0) {
          SetIsEnd(true);
        }
      });
    }
  }, [objectSearch, isSearching]);
  const addListComment = (data, isInit) => {
    var lst = [];
    if (!isInit) {
      lst = [...lstComment];
    }

    if (data == null || data.length == 0) {
      SetIsEnd(true);
      setIsSearching(false);
      return;
    }

    for (let index = 0; index < data.length; index++) {
      var cm = data[index];
      if (cm.isreply === true) {
        cm.isread = true;
      }
      cm.childtext = processChildText(cm.childtext);
      if (cm.lastupdatetime) {
        cm.lastupdatetime = cm.lastupdatetime.replace("Z", "");
      }

      var pos = lst.findIndex((item) => item.id === cm.id);
      if (pos === -1) {
        lst.push(cm);
      }
    }
    var lstSort = getSortList(lst);
    setLstComment(lstSort);
    setIsSearching(false);
  };

  const getSortList = (lst) => {
    var lstNew = lst.map(function (item, index) {
      var it = { ...item };
      if (it.isreply == true) {
        it.isread = true;
      }
      return it;
    });
    return lstNew.sort((a, b) => {
      if ((a.isread == false ? 0 : 1) < (b.isread == false ? 0 : 1)) {
        return -1;
      } else {
        if ((a.IsRead == false ? 0 : 1) == (b.IsRead == false ? 0 : 1)) {
          if (moment(a.lastupdatetime).isAfter(b.lastupdatetime)) {
            return -1;
          }
        }
      }

      return 1;
    });
  };

  const processChildText = (text) => {
    if (text == null) return null;
    text = text.replace("<br/>", "");
    var patt = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/g;
    var links = text.match(patt);
    if (links) {
      links.forEach((a) => {
        text = text.replace(a, " [link] ");
      });
    }

    patt = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    links = text.match(patt);
    if (links) {
      links.forEach((a) => {
        text = text.replace(a, " [link] ");
      });
    }

    return text;
  };
  const loadMoreComment = () => {
    setObjectSearch(
      (prev) => ({ ...prev, pagging: prev.pagging + 1 }),
      () => {
        api.post("Facebook/GetListFBComment", objectSearch).then((res) => {
          addListComment(res.data);
        });
      }
    );
  };

  const onItemSelected = (item) => {
    setSelectedComment(item);
  };

  const updateObjectSearch = (name, value) => {
    setCheckedItems([]);
    if (name == "beginhour" || name == "endhour") {
      if (!value) {
        value = "00:00";
      }
    }
    if (typeof name === "object") {
      objectSearch = { ...objectSearch, ...name };
      setObjectSearch(objectSearch);
    } else {
      if (
        (name == "typemessage" || name == "haveread" || name == "havephone" || name == "havereply" || name == "haveorder" || name == "customertype") &&
        objectSearch[name] === value
      ) {
        objectSearch[name] = "";
      } else {
        objectSearch[name] = value;
      }
      setObjectSearch(objectSearch);
      if (objectSearch["typemessage"] != "inbox") {
        setShowCheck(false);
      }
    }
    getListFBComment();
  };

  const clearFilterChat = () => {
    setShowCheck(false);
    setCheckedItems([]);
    setObjectSearch({
      pageSelect: [],
      typemessage: "",
      haveread: "",
      havereply: "",
      havephone: "",
      begintime: "",
      endtime: "",
      post: "",
      tag: "",
      search: "",
      pagging: 1,
      curentView: 0,
      userid: "",
      assignto: "",
      beginhour: "00:00",
      endhour: "23:59",
      customertype: "",
      haveorder: "",
    });
    getListFBComment();
  };
  const handleInputSearchChange = (e) => {
    objectSearch.search = e.target.value;
    setObjectSearch(objectSearch);
  };
  const onTagChange = (newTags, commentid) => {
    setLstComment((prevComments) => prevComments.map((comment) => (comment.id === commentid ? { ...comment, tag: newTags } : comment)));
  };
  const checkboxChange = (item, isChecked) => {
    setCheckedItems((prevCheckedItems) => {
      if (isChecked) {
        var exist = prevCheckedItems.find((x) => x.id == item.id);
        if (exist) return prevCheckedItems;
        else return [...prevCheckedItems, item];
      } else {
        return prevCheckedItems.filter((x) => x.id !== item.id);
      }
    });
  };
  const openSendChat = () => {
    if (!check && objectSearch["typemessage"] != "inbox") {
      updateObjectSearch("typemessage", "inbox");
    } else {
      setCheckedItems([]);
    }
    setShowCheck(!check);
  };

  const onCheckAllChange = (e) => {
    const allItemIds = [...lstComment];
    setCheckedItems(e.target.checked ? allItemIds : []);
  };

  const onCheckAllFriendChange = (e) => {
    lstFriends.forEach((x) => {
      checkboxChange({ id: x.userId, is_zalo: true, userid: x.userId }, e.target.checked);
    });
  };

  const handleCloseChat = () => {
    setVisibleChat(false);
    getListFBComment();
  };
  const showModalChats = () => {
    if (checkedItems.length <= 0) {
      message.error("Bạn chưa chọn hội thoại nào");
    } else setVisibleChat(true);
  };
  useEffect(() => {
    getListFBComment();
  }, []);

  const clickZaloItem = (data) => {
    api.post("ZaloWebhook/GetZaloParentInbox", data).then((res) => {
      setSelectedComment(res.data);
    });
  };

  const searchZaloFriends = () => {
    const phoneRegex = /^(?:\+84|0)([\s.-]?\d{2,3})([\s.-]?\d{3})([\s.-]?\d{4})$/;
    if (phoneRegex.test(zaloSearchText)) {
      axios
        .request({
          method: "get",
          url: zaloUrl + "find-user?phone=" + zaloSearchText,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.uid) {
            setUserSearch(res.data);
            setHeight(160);
          } else {
            setUserSearch(null);
            setHeight(100);
          }
        })
        .catch();
    } else {
      var key = createSearchText(zaloSearchText);
      var lst = lstFriends.filter((x) => createSearchText(x.zaloName).includes(key) || createSearchText(x.displayName).includes(key));
      setLstFriendsDisplay(lst);
    }
  };
  return (
    <>
      <SidebarMenu
        getListFBComment={getListFBComment}
        objectSearch={objectSearch}
        updateObjectSearch={updateObjectSearch}
        clearFilterChat={clearFilterChat}
        updateSearchDateTime={{}}
      />
      <div className="user-list-container">
        <Tabs
          activeKey={activeKey}
          centered
          onChange={(e) => {
            onTabChange(e);
          }}
          items={tabs}
          style={{ background: "#fff" }}
        ></Tabs>
        <div hidden={activeKey != "1"} style={{ marginTop: 3 }}>
          <div className="user-list-header">
            <Input
              placeholder="Tìm kiếm theo họ tên, SĐT"
              prefix={<SearchOutlined />}
              onChange={(e) => handleInputSearchChange(e)}
              onPressEnter={getListFBComment}
              className="search-input"
            ></Input>
            <Tooltip title="Tìm theo nội dung">
              <Checkbox
                onChange={(e) => {
                  setCheckSearchByContent(e.target.checked);
                }}
                checked={checkSearchByContent}
                style={{ marginRight: "7px", float: "right" }}
              ></Checkbox>
            </Tooltip>
            <Button icon={<NodeExpandOutlined />} onClick={openSendChat} type="text" className="action-icon-header" />
            <Button icon={<ReloadOutlined />} onClick={getListFBComment} type="text" className="action-icon-header" />
          </div>

          <div className="scrollable-list">
            {check && (
              <>
                <div style={{ padding: "5px" }}>
                  <Button icon={<MessageOutlined />} onClick={showModalChats} style={{ backgroundColor: "#28a745", color: "white" }}>
                    Gửi tin nhắn
                  </Button>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    style={{ marginRight: "7px", float: "right" }}
                  ></Checkbox>
                </div>
              </>
            )}
            <ListComment
              itemLayout="horizontal"
              lstComment={lstComment}
              selectedComment={selectedComment}
              isSearching={isSearching}
              onClick={onItemSelected}
              loadMoreComment={loadMoreComment}
              showCheckbox={check}
              checkedItems={checkedItems}
              onshowMulChat={checkboxChange}
              isEnd={isEnd}
            />
          </div>
        </div>
        <div hidden={activeKey != "2"} style={{ marginTop: 3 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="user-list-header">
              <Input
                placeholder="Tìm kiếm theo họ tên, SĐT"
                prefix={<SearchOutlined />}
                value={zaloSearchText}
                onChange={(e) => {
                  setZaloSearchText(e.target.value);
                }}
                onPressEnter={() => {
                  searchZaloFriends();
                }}
                className="search-input"
              ></Input>
              <Button icon={<NodeExpandOutlined />} onClick={openSendChat} type="text" className="action-icon-header" />
              <Button icon={<ReloadOutlined />} onClick={getListFriend} type="text" className="action-icon-header" />
            </div>
            {userSearch ? (
              <div className={`custom-list-item`}>
                <div className="avatar-section">
                  <div style={{ position: "relative" }}>
                    <Badge count={userSearch.unreadcount}>
                      <div style={{ position: "relative" }}>
                        <Avatar src={userSearch.avatar} size="large" />
                      </div>
                    </Badge>
                  </div>
                </div>

                <div style={{ display: "flex", height: 38 }}>
                  <div style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold" }}>
                    <div>{userSearch.zalo_name}</div>
                  </div>
                </div>
                <div className="action-section" style={{ display: "flex", justifyContent: "flex-end", flex: 1, height: 38 }}>
                  <div className="action-buttons" style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        if (userSearch) {
                          api.get("ZaloWebhook/RequestAddFriend?id=" + userSearch.uid).then((res) => {
                            message.success("Thành công");
                          });
                        }
                      }}
                    >
                      Kết bạn
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="infinite-conv-list" style={{ height: `calc(100vh - ${height}px)`, overflow: "hidden scroll" }}>
              {loadingFriend ? (
                <div style={{ padding: 20 }}>
                  <Skeleton></Skeleton>
                </div>
              ) : (
                <div>
                  {check && (
                    <>
                      <div style={{ padding: "5px" }}>
                        <Button icon={<MessageOutlined />} onClick={showModalChats} style={{ backgroundColor: "#28a745", color: "white" }}>
                          Gửi tin nhắn
                        </Button>
                        <Checkbox
                          indeterminate={indeterminateFriend}
                          onChange={onCheckAllFriendChange}
                          checked={checkAllFriend}
                          style={{ marginRight: "7px", float: "right" }}
                        ></Checkbox>
                      </div>
                    </>
                  )}
                  {lstFriendsDisplay.map((data) => {
                    return (
                      <div className={`custom-list-item ${data.userId === selectedComment?.id ? "selected-comment" : ""}`} onClick={() => clickZaloItem(data)}>
                        <div className="avatar-section">
                          <div style={{ position: "relative" }}>
                            <Badge count={data.unreadcount}>
                              <div style={{ position: "relative" }}>
                                <Avatar src={data.avatar} size="large" />
                              </div>
                            </Badge>
                          </div>
                        </div>

                        <div style={{ display: "flex", height: 38 }}>
                          <div style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "bold" }}>
                            <div>{data.zaloName}</div>
                          </div>
                        </div>
                        <div className="action-section" style={{ display: "flex", justifyContent: "flex-end", flex: 1, height: 38 }}>
                          <div className="action-buttons" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <div className="input-icons">
                              {check && (
                                <Checkbox
                                  checked={checkedItems.some((x) => x.id == data.userId)}
                                  onChange={(e) => checkboxChange({ id: data.userId, is_zalo: true, userid: data.userId }, e.target.checked)}
                                ></Checkbox>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Content style={{ background: "#f0f2f5", minWidth: "500px" }}>
        {selectedComment && selectedComment.id ? (
          <DetailChat typesearch={objectSearch.typemessage} selectedComment={selectedComment} onTagChange={onTagChange} />
        ) : (
          <Empty style={{ height: "50px" }} description="Chọn Comment hoặc Inbox" />
        )}
      </Content>
      <Modal centered={false} visible={visibleChat} onCancel={handleCloseChat} title={<span>Gửi tin nhắn hoàng loạt</span>} width="50%" footer={null}>
        <div>
          <span style={{ paddingTop: "10px" }}>
            <DetailChat
              onTagChange={onTagChange}
              closeMultipleChat={handleCloseChat}
              lstSelectChat={checkedItems}
              type={"multiple"}
              height={window.innerHeight - 500}
              typesearch={"inbox"}
            />
          </span>
        </div>
      </Modal>
    </>
  );
};

export default Chat;
