import React from "react";
import { formatCurrency } from "common/Format2";
import { CloseOutlined } from "@ant-design/icons";
import { Header } from "Client";
import dayjs from "dayjs";

const Bill = React.forwardRef((props, ref) => {
  const { lastTransaction, lstPurchase, lstReturn, payData, type, header, customer } = props;
  return (
    <div ref={ref} style={type == "web" ? { backgroundColor: "#ffffff", borderRadius: 10 } : {}}>
      <div style={{ padding: "0 15px" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img src="/image/logo.jpg" style={{ width: "40%", borderRadius: 5 }} />
        </div>
        <div
          style={{
            marginBottom: 10,
            fontWeight: "bold",
            borderTop: "2px solid",
            borderBottom: "2px solid",
            padding: "5px 0",
            display: "flex",
            justifyContent: "space-between",
            fontSize: 10,
          }}
        >
          <div>
            <div>Địa chỉ: 44 Dương Tử Giang p14 q5 TPHCM</div>
            <div>Số điện thoại 1: 0903.102.988(Trang)</div>
            <div>Số điện thoại 1: 0909.408.685(Điền)</div>
          </div>
          <div>
            <div>Phương thức thanh toán:</div>
            <div>Chủ tài khoản: </div>
            <div>Hoàng Duy</div>
          </div>
          <div>
            <div>Sacombank: 0602.352.766.85</div>
            <div>Agribank: 6221.205.193.813</div>
            <div>VPbank: 128.685.502</div>
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          {type != "web" ? (
            <div
              style={{
                fontWeight: "bold",
                fontSize: 18,
                textAlign: "center",
                backgroundColor: "#fff",
              }}
            >
              <div>{dayjs().format("DD/MM/YYYY")}</div>
              <div>{customer ? customer.name : ""}</div>
            </div>
          ) : (
            <></>
          )}

          <div style={{ marginBottom: 20 }}>
            {lstPurchase.length != 0 ? (
              <div>
                {lstPurchase.map((x, index) => {
                  return (
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{x.goods_type == "clothes" ? x.product_name : x.fabric_name}</div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          {x.goods_type == "clothes" ? (
                            x.quantity
                          ) : (
                            <>
                              {`${x.quantity}`} &nbsp;&nbsp;
                              <CloseOutlined />
                              &nbsp;&nbsp; {`${x.fabric_height}m`}
                            </>
                          )}
                        </div>
                        <div>
                          <CloseOutlined />
                        </div>
                        <div>{formatCurrency(x.price)}đ</div>
                        <div>=</div>
                        <div>{formatCurrency(x.quantity * x.price * (x.fabric_height ?? 1))}đ</div>
                      </div>
                    </div>
                  );
                })}

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ float: "right" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 150 }}>
                      <div style={{ float: "right" }}>Tổng cộng:</div>
                      <div style={{ width: 65 }}>
                        <div style={{}}>
                          {formatCurrency(
                            lstPurchase.reduce((tong, x) => {
                              return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                            }, 0)
                          )}
                          đ
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <hr style={{ width: "100%", borderTop: "1px solid #000" }} />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ float: "right" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: 150 }}>
                <div style={{ float: "right" }}>
                  <div>Sổ cũ:</div>
                  <div>Thanh toán:</div>
                  <div>Trả hàng:</div>
                </div>
                <div style={{ width: 65 }}>
                  <div
                    style={lastTransaction && lastTransaction.profit < 0 ? { color: "red" } : {}}
                    className={payData.price != null && payData.price != 0 ? "red-text" : ""}
                  >
                    {lastTransaction && lastTransaction.profit != 0 ? formatCurrency(lastTransaction.profit) : 0}đ
                  </div>

                  <div
                    style={payData.price != null && payData.price != 0 ? { color: "red" } : {}}
                    className={payData.price != null && payData.price != 0 ? "red-text" : ""}
                  >
                    {formatCurrency(payData.price)}đ
                  </div>
                  <div
                    style={
                      lstReturn.reduce((tong, x) => {
                        return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                      }, 0) != 0
                        ? { color: "red" }
                        : {}
                    }
                    className={payData.price != null && payData.price != 0 ? "red-text" : ""}
                  >
                    {formatCurrency(
                      lstReturn.reduce((tong, x) => {
                        return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                      }, 0)
                    )}
                    đ
                  </div>
                </div>
              </div>

              <hr style={{ width: "100%", borderTop: "1px solid #000" }} />

              <div style={{ display: "flex", justifyContent: "space-between", width: 150 }}>
                <div style={{ float: "right" }}>Tổng:</div>

                <div style={{ width: 65 }}>
                  <div
                    style={
                      lstPurchase.reduce((tong, x) => {
                        return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                      }, 0) +
                        (lastTransaction && lastTransaction.profit != 0 ? lastTransaction.profit : 0) -
                        (payData.price ?? 0) -
                        lstReturn.reduce((tong, x) => {
                          return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                        }, 0) >=
                      0
                        ? { float: "left" }
                        : { float: "left", color: "red" }
                    }
                    className={
                      lstPurchase.reduce((tong, x) => {
                        return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                      }, 0) +
                        (lastTransaction && lastTransaction.profit != 0 ? lastTransaction.profit : 0) -
                        (payData.price ?? 0) -
                        lstReturn.reduce((tong, x) => {
                          return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                        }, 0) >=
                      0
                        ? ""
                        : "red-text"
                    }
                  >
                    {formatCurrency(
                      lstPurchase.reduce((tong, x) => {
                        return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                      }, 0) +
                        (lastTransaction && lastTransaction.profit != 0 ? lastTransaction.profit : 0) -
                        (payData.price ?? 0) -
                        lstReturn.reduce((tong, x) => {
                          return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                        }, 0)
                    )}
                    đ
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Bill;
